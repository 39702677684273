import React from 'react'

const GoogleIcon = () => {
    return (
        // <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M8.93521 7.30007V10.7511H13.7272C13.2812 12.9441 11.4142 14.2041 8.93521 14.2041C6.04776 14.1643 3.72787 11.8123 3.72787 8.92457C3.72787 6.03684 6.04776 3.68481 8.93521 3.64507C10.1354 3.64363 11.2987 4.06014 12.2252 4.82307L14.8252 2.22307C11.8428 -0.398861 7.48721 -0.736093 4.13677 1.39552C0.786319 3.52712 -0.753767 7.61528 0.357458 11.4277C1.46868 15.2401 4.96416 17.8605 8.93521 17.8581C13.4022 17.8581 17.4642 14.6091 17.4642 8.92407C17.4573 8.37671 17.3902 7.83177 17.2642 7.29907L8.93521 7.30007Z" fill="#4f4f4f" />
        // </svg>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 16 16">
            <path d="M 7.5 1 C 3.921875 1 1 3.921875 1 7.5 C 1 11.078125 3.921875 14 7.5 14 C 10.878906 14 13.671875 11.398438 13.96875 8.089844 L 14.066406 7 L 8 7 L 8 9 L 11.671875 9 C 11.046875 10.734375 9.457031 12 7.5 12 C 5.003906 12 3 9.996094 3 7.5 C 3 5.003906 5.003906 3 7.5 3 C 8.691406 3 9.765625 3.460938 10.570313 4.210938 L 11.9375 2.75 C 10.773438 1.664063 9.210938 1 7.5 1 Z"></path>
        </svg>
    )
}

export default GoogleIcon