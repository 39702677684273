import React, { useContext, useEffect, useState } from "react";
import { ContactContext, UserContext, VendorContext } from "../../../App";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { tele, telecount } from "../../../services/constants";
import ReactFlagsSelect from "react-flags-select";
import Card from "../../Card/Card";
import UserIcon from "../../../SVGs/UserIcon";
import RightArrow from "../../../SVGs/RightArrow";
const BuyerDetails = () => {
  const history = useHistory();
  const { contactDetails, setContactDetails } = useContext(ContactContext);
  const details = useContext(VendorContext);
  const { userDetails } = useContext(UserContext);

  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    setCountryCode(
      Object.keys(telecount).map((ele) => {
        if (ele == contactDetails.phoneCode) {
          return telecount[ele].split(" ")[
            telecount[ele].split(" ").length - 1
          ];
        }
      })
    );
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("contactInfo"))) {
      const contactInfo = JSON.parse(localStorage.getItem("contactInfo"));

      setContactDetails({
        ...contactDetails,
        phoneCode: contactInfo.code,
        phone: contactInfo.phone,
      });
    }
  }, []);

  return (
    <>
      {/* <Link to='/info' className='buyer-details-mainDIv'>
                <div className='buyer-details-firstDiv'>
                    <div className='buyer-details-name'>
                        {contactDetails?.name}
                    </div>

                    <div className='buyer-phone-number'>
                        <div className='dontchane-direction'>
                            <ReactFlagsSelect
                                className='showFlag-only'
                                selected={contactDetails?.phoneCode}
                                showSelectedLabel={false}
                                disabled
                                customLabels={telecount}
                            />
                            +{tele[contactDetails?.phoneCode]} {contactDetails?.phone}
                        </div>
                    </div>
                </div>
                <div className='buyer-details-secondDiv'>
                    <i className="fa fa-angle-right right-arrow"></i>
                </div>
            </Link> */}

      {console.log(contactDetails?.phoneCode, "In buyers details")}

      <Card
        cardClick={() =>
          userDetails?.is_guest
            ? history.push("/contact-details")
            : history.push("/info")
        }
        icon={<UserIcon />}
        info={{
          name: contactDetails?.name,
          flag: (
            <ReactFlagsSelect
              className="showFlag-only"
              selected={contactDetails?.phoneCode}
              showSelectedLabel={false}
              disabled
              customLabels={telecount}
            />
          ),
          phoneCode: contactDetails?.phoneCode,
          phone: contactDetails?.phone,
          email: contactDetails?.email,
        }}
      />
    </>
  );
};

export default BuyerDetails;
