import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  AreaContext,
  CartContext,
  InterNationalContext,
  LanguageContext,
  VendorContext,
} from "../../../App";
import { useHistory } from "react-router-dom";

function ReviewBar() {
  const history = useHistory();
  const { cart } = useContext(CartContext);
  const { language } = useContext(LanguageContext);
  const { areaDetails, setOpenArea } = useContext(AreaContext);
  const details = useContext(VendorContext);
  const { internationalDelivery } = useContext(InterNationalContext);

  return (
    <div
      className={`bottom-button ${
        details?.vendor?.home_page_type == "18"
          ? "bottom-button-full"
          : "bottom-button-half"
      }`}
      onClick={() => {
        if (
          (areaDetails?.type != "delivery" || areaDetails?.area == "") &&
          (areaDetails?.type != "pickup" || areaDetails?.branch == "") &&
          details?.vendor?.home_page_type != "18" &&
          internationalDelivery.delivery_country_code.toUpperCase() === "KW"
        ) {
          setOpenArea((prev) => ({ open: true, goHome: false }));
        } else {
          history.push("/review");
        }
      }}
    >
      <Link
        // to={
        //   (areaDetails?.type != "delivery" || areaDetails?.area == "") &&
        //   (areaDetails?.type != "pickup" || areaDetails?.branch == "") &&
        //   details?.vendor?.home_page_type != "18" &&
        //   internationalDelivery.delivery_country_code.toUpperCase() === "KW"
        //     ? `/area`
        //     : `/review`
        // }
        className={`text-center checkout-button order-review-details ${
          details?.vendor?.home_page_type == "18" ? "fashion-checkout-page" : ""
        }`}
      >
        <span className="added-count">{cart?.cartCount}</span>
        <span className="review-img">
          <img
            src="pictures/cart-small.png"
            className="img-fluid"
            height="25px"
            width="25px"
          />
        </span>
        <span className="order-review">
          <span className="review-order">
            {language === "ltr" ? "Review Order" : "مراجعة الطلب"}
          </span>
        </span>
        <span className="order-with-price">
          <span className="price-count">
            <span>
              {cart?.subTotal ? parseFloat(cart?.subTotal).toFixed(3) : 0}
            </span>{" "}
            {language === "rtl" ? "د.ك" : "KD"}
          </span>
        </span>
      </Link>
    </div>
  );
}

export default ReviewBar;
