import React, { useContext, useEffect } from "react";
import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Link } from "react-router-dom";
import { deleteB } from "../../../api";
import { LanguageContext, VendorContext } from "../../../App";
import { telecount } from "../../../services/constants";

function ReserverDetails({
  stepper,
  setStepper,
  contactDetails,
  bookingDetails,
  setContactDetails,
}) {
  const [errorState, setErrorState] = useState({
    emailError: false,
    emailErrorMessage: "",
    emailErrorMessagear: "",
    nameError: false,
    nameErrorMessage: "",
    nameErrorMessagear: "",
    phoneError: false,
    phoneErrorMessage: "",
    phoneErrorMessagear: "",
  });


  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);

  const nameValidation = (value) => {
    let pattern =
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ ]*$/;
    if (value == "") {
      setErrorState((errorState) => ({
        ...errorState,
        nameErrorMessage: "This field is compulsory",
        nameErrorMessagear: "مطلوب ملء هذا الحقل",
        nameError: true,
      }));
      return true;
    } else if (value.match(pattern)) {
      setErrorState((errorState) => ({
        ...errorState,
        nameErrorMessage: "",
        nameError: false,
        nameErrorMessagear: "",
      }));
      return false;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        nameErrorMessage: "Only alphabets are allowed",
        nameError: true,
        nameErrorMessagear: "يسمح فقط الحروف الهجائية",
      }));
      return true;
    }
  };

  const phoneValidation = (value) => {
    let pattern = /^[0-9]+$/;
    let kwpattern = /^[124965]\d+$/;
    if (value === "") {
      setErrorState((errorState) => ({
        ...errorState,
        phoneErrorMessage: "This field is compulsory",
        phoneError: true,
        phoneErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else if (value.length < 8) {
      setErrorState((errorState) => ({
        ...errorState,
        phoneErrorMessage: "Please enter at least 8 characters",
        phoneError: true,
        phoneErrorMessagear: "الرجاء إدخال 8 أرقام",
      }));
      return true;
    } else if (!value.match(pattern)) {
      setErrorState((errorState) => ({
        ...errorState,
        phoneErrorMessage: "Only numbers allowed",
        phoneError: true,
        phoneErrorMessagear: "مسموح بالأرقام فقط",
      }));
      return true;
    } else if (contactDetails?.phoneCode == "KW" && !value.match(kwpattern)) {
      setErrorState((errorState) => ({
        ...errorState,
        phoneErrorMessage: "Enter a valid phone number",
        phoneError: true,
        phoneErrorMessagear: "أدخل رقم هاتف صالح",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        phoneErrorMessage: "",
        phoneError: false,
        phoneErrorMessagear: "",
      }));
      return false;
    }
  };

  const emailValidation = (value) => {
    let pattern =
      /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|co.in)\b/;
    if (value.match(pattern) || value === "") {
      setErrorState((errorState) => ({
        ...errorState,
        emailErrorMessage: "",
        emailError: false,
        emailErrorMessagear: "",
      }));
      return false;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        emailErrorMessage: "Enter a valid email",
        emailError: true,
        emailErrorMessagear: "أدخل بريد إلكتروني متاح",
      }));
      return true;
    }
  };

  const onNextCLick = () => {
    let email = emailValidation(contactDetails.email);
    let phone = phoneValidation(contactDetails.phone);
    let name = nameValidation(contactDetails.name);
    if (!email && !phone && !name) {
      setStepper((stepper) => stepper + 1);
    }
  };
  return (
    <>
      <div style={{ paddingBottom: 10 }}>
        <div className="checkout-step-one">
          <h1 className="main-heading">
            {language == "ltr" ? "Contact Information" : "معلومات الاتصال"}
          </h1>
          <p className="sub-text pb-3">
            {language == "ltr"
              ? "We’ll use it to get back to you for reservation updates"
              : "سنستخدمه للرد عليك لتحديثات الحجز"}
          </p>
          <div className="checkout-one" style={{ paddingBottom: 40 }}>
            <div className="form-div">
              <label className="label-name">
                {language == "ltr" ? "Full Name" : "الاسم الكامل"}
                <sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                id="name"
                name="first_name"
                required="true"
                value={contactDetails.name}
                onChange={(e) => {
                  nameValidation(e.target.value);
                  setContactDetails({
                    ...contactDetails,
                    name: e.target.value,
                  });
                }}
              ></input>
              {errorState.nameError && (
                <label className="error-text">
                  {language == "ltr"
                    ? errorState.nameErrorMessage
                    : errorState.nameErrorMessagear}
                </label>
              )}
            </div>
            <div className="form-div">
              <label className="label-name">
                {language == "ltr" ? "Phone" : "هاتف"}
                <sup>*</sup>
              </label>
              {console.log(contactDetails?.phoneCode)}
              <div style={{ display: "flex", alignItems: "center" }}>
                <ReactFlagsSelect
                  selected={contactDetails?.phoneCode}
                  searchable={true}
                  showSelectedLabel={false}
                  customLabels={telecount}
                  onSelect={(code) => {
                    setContactDetails({
                      ...contactDetails,
                      phoneCode: code,
                      phone: contactDetails?.phone?.substring(
                        0,
                        code == "KW" ? 8 : 12
                      ),
                    });
                  }}
                />
                <input
                  type="tel"
                  className="form-control"
                  placeholder=""
                  id="name"
                  name="phone"
                  required="true"
                  value={contactDetails.phone}
                  onChange={(e) => {
                    if (e.target.value.match(/^([0-9]|[٠-٩])*$/))
                      if (
                        e.target.value.length <=
                        (contactDetails?.phoneCode == "KW" ? 8 : 12)
                      ) {
                        let k = e.target.value.substring(
                          0,
                          contactDetails?.phoneCode == "KW" ? 8 : 12
                        );
                        e = "٠".charCodeAt(0);
                        k = k.replace(/[٠-٩]/g, function (t) {
                          return t.charCodeAt(0) - e;
                        });
                        phoneValidation(k);
                        setContactDetails({
                          ...contactDetails,
                          phone: k,
                        });
                      }
                  }}
                ></input>
              </div>
              {errorState.phoneError && (
                <label className="error-text">
                  {language == "ltr"
                    ? errorState.phoneErrorMessage
                    : errorState.phoneErrorMessagear}
                </label>
              )}
            </div>
            <div className="form-div">
              <label className="label-name">
                {language == "ltr"
                  ? "E-mail (optional)"
                  : "البريد الإلكتروني (إختياري)"}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                id="name"
                name="email"
                value={contactDetails.email}
                onChange={(e) => {
                  emailValidation(e.target.value);
                  setContactDetails({
                    ...contactDetails,
                    email: e.target.value,
                  });
                }}
              ></input>
              {errorState.emailError && (
                <label className="error-text">
                  {language == "ltr"
                    ? errorState.emailErrorMessage
                    : errorState.emailErrorMessagear}
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-button iphone-issue"
        style={{ position: "absolute", width: "100%" }}
      >
        <Link
          onClick={(e) => {
            e.preventDefault();
            deleteB({
              vendorId: details.vendor.booking_vendor_id,
              userId: localStorage.getItem("userID"),
              mainVendor:
                details?.vendor?.home_page_type != 2 &&
                  details?.vendor?.home_page_type != 4
                  ? bookingDetails?.branch?.booking_vendor_id
                  : bookingDetails?.branch?.available_place?.[
                    bookingDetails?.place
                  ]?.booking_vendor_id,
            });
            setStepper((s) => s - 1);
          }}
          className="text-center checkout-button checkout-button-back"
        >
          {language == "ltr" ? "Back" : "تراجع"}
        </Link>
        <Link
          onClick={(e) => {
            e.preventDefault();
            onNextCLick();
          }}
          className="text-center checkout-button checkout-button-next"
        >
          {language == "ltr" ? "Next" : "متابعة"}
        </Link>
      </div>
    </>
  );
}

export default ReserverDetails;
