import React, { useContext, useEffect } from "react";
import {
  AreaContext,
  CartContext,
  ContactContext,
  LanguageContext,
  UserContext,
  VendorContext,
} from "../../../App";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const RestSideDrawerContent = ({ setBurger }) => {
  const { language } = useContext(LanguageContext);
  const { setUserDetails } = useContext(UserContext);
  const { contactDetails, setContactDetails, setAddressDetails, setPayment } =
    useContext(ContactContext);
  const { setAreaDetails } = useContext(AreaContext);
  const { setCart } = useContext(CartContext);
  const details = useContext(VendorContext);
  const history = useHistory();
  const contactInfo = JSON.parse(localStorage.getItem("contactInfo") || "{}");

  const sectionsArray = [
    {
      englishType:
        details?.vendor?.slug_type == 2
          ? "Reserve"
          : details?.vendor?.business_type == 6
          ? "Menu"
          : "Home",
      arabicType:
        details?.vendor?.slug_type == 2
          ? "الحجوزات"
          : details?.vendor?.business_type == 6
          ? "الصفحة الرئيسية"
          : "الصفحة الرئيسية",
      link: "/",
    },
    {
      englishType: "Branch",
      arabicType: "افرعنا",
      link: "/branches",
    },
    // {
    //     englishType: "FAQs",
    //     arabicType: "الأسئلة الشائعة",
    //     link: ""
    // },
    {
      englishType: "Track Order",
      arabicType: "تعقب الطلب",
      link: "/track-order",
    },
    {
      ...(contactInfo?.phone &&
      contactInfo?.email &&
      contactInfo?.name &&
      details?.vendor?.checkout_method != null &&
      localStorage.getItem("token")
        ? {
            englishType: "My Information",
            arabicType: "معلوماتي",
            link: "",
          }
        : null),
    },
    {
      ...(contactInfo?.phone &&
      contactInfo?.email &&
      contactInfo?.name &&
      details?.vendor?.checkout_method != null &&
      localStorage.getItem("token")
        ? {
            englishType: "My Orders",
            arabicType: "مشترياتي",
            link: "",
          }
        : null),
    },
    {
      ...(contactInfo?.phone &&
      contactInfo?.email &&
      contactInfo?.name &&
      details?.vendor?.checkout_method != null &&
      localStorage.getItem("token")
        ? {
            englishType: "Logout",
            arabicType: "تسجيل الخروج",
            link: "",
          }
        : details?.vendor?.checkout_method != null
        ? {
            englishType: "Login",
            arabicType: "تسجيل الدخول",
            link: "",
          }
        : null),
    },
  ];
  const navigateFunction = (section) => {
    switch (section.englishType) {
      case "FAQs":
        break;
      case "Track Order":
        setBurger(false);
        history.push("/track-order");
        break;

      case "My Orders":
        setBurger(false);
        history.push("/order-history");
        break;
      case "Logout":
        setBurger(false);
        setAreaDetails({
          type:
            window.location.host.replace(/^www\./, "") !== "shop.playon.today"
              ? "delivery"
              : "pickup",
          data: {},
          area: "",
          branch: "",
          branch_id: "",
          area_id: "",
          branchForArea: {},
          deliveryTiming: "",
          pickupTiming: "",
          customDelivery: false,
          getDeliveryTiming: moment().add(2, "hours").toDate(),
          laterDeliveryTiming: moment().add(2, "hours").toDate(),
          laterPickupTiming: moment().add(2, "hours").toDate(),
          now: 1,
          ar_area: "",
          ar_branch: "",
          ar_deliveryTiming: "",
          ar_pickupTiming: "",
          shopOpen: 1,
          minimum: "",
          branch_lat: "",
          branch_lng: "",
        });
        setCart({});
        setAddressDetails({
          block: "",
          street: "",
          avenue: "",
          house: "",
          floor: "",
          flat: "",
          special_directions: "",
          lat: 29.378,
          lng: 47.99,
          fixedLat: 29.378,
          fixedLng: 47.99,
          addressString: "",
          addressType: "1",
        });
        setContactDetails({
          name: "",
          phoneCode: "KW",
          phone: "",
          email: "",
          model: "",
          color: "",
          license: "",
        });
        setPayment(1);
        setUserDetails({
          id: "",
          email: "",
          firstname: "",
          lastname: "",
          firstname_ar: "",
          lastname_ar: "",
          mobilenumber: "",
          country_code: "",
          is_guest: false,
          is_social: false,
        });

        localStorage.removeItem("contactInfo");
        localStorage.removeItem("token");
        localStorage.removeItem("cartTime");
        localStorage.removeItem("newPath");
        localStorage.removeItem("userID");
        let result = "";
        let characters = "abcdefghijklmnopqrstuvwxyz0123456789";
        let charactersLength = characters.length;
        for (var i = 0; i < 10; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        localStorage.setItem("userID", result);
        history.push("/");
        break;

      case "My Information":
        setBurger(false);
        history.push("/info");
        break;

      case "Login":
        setUserDetails((prev) => ({ ...prev, is_guest: false }));
        setBurger(false);
        history.push("/contact-details");
        break;
      case "Home":
      case "Reserve":
      case "Menu":
        setBurger(false);
        history.push("/");
        break;
      case "Branch":
        setBurger(false);
        history.push("/branches");
        break;
      default:
        break;
    }
  };
  return (
    <div style={{ marginBottom: "360px" }}>
      {sectionsArray &&
        sectionsArray
          .filter((ele) => Object.keys(ele).length != 0)
          .map((section, i) => (
            <div
              key={i}
              className="restSide-div"
              onClick={() => navigateFunction(section)}
            >
              {language === "ltr" ? section.englishType : section.arabicType}
            </div>
          ))}
    </div>
  );
};

export default RestSideDrawerContent;
