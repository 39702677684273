import axios from "axios";
import { API_URL } from "../../services/constants";

export const changeArea = async ({
  vendors_id,
  area_id,
  vendorSlug,
  user_string,
}) => {
  try {
    const response = await axios.post(
      `${API_URL}/change-area`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_id: vendors_id,
        area_id: area_id,
        vendor_slug: vendorSlug,
        user_string: user_string,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getScheduleTime = async ({ vendors_id, area_id, vendorSlug }) => {
  try {
    const response = await axios.post(
      `${API_URL}/get-schedule-time`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_id: vendors_id,
        area_id: area_id,
        vendor_slug: vendorSlug,
        lang: "ar",
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDeliveryPickupList = async ({ vendors_id, vendorSlug }) => {
  try {
    const response = await axios.post(
      `${API_URL}/delivery-pickup-list`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_id: vendors_id,
        vendor_slug: vendorSlug,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getEstorebraches = async ({ vendors_id, vendorSlug }) => {
  try {
    const response = await axios.post(
      `${API_URL}/branches`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_id: vendors_id,
        vendor_slug: vendorSlug,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
