import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CategoryContainer from "./components/CategoryContainer";
import Footer from "./components/Footer";
import MobileVendorLogo from "./components/MobileVendorLogo";
import OrderType from "./components/OrderType";
import SearchBar from "./components/SearchBar";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPixel from "react-facebook-pixel";
import {
  AreaContext,
  CartContext,
  ContactContext,
  InterNationalContext,
  LanguageContext,
  SearchContext,
  UserContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import ProductsList from "../Products/components/ProductsList";
import ReviewBar from "./components/ReviewBar";
import MobileNavigationBar from "./components/MobileNavigationBar";
import CategoryListView from "../CategoryListView";
import CategorySelect from "../CategorySelect";
import ScrollView from "../ScrollView";
import SearchNone from "./components/SearchNone";
import WhatsappSticky from "../WhatsappSticky";
import moment from "moment";
import ToggleCategory from "../ToggleCategory";
import CategoryDrop from "./components/CategoryDrop";
import { ClipLoader } from "react-spinners";
import SnapPixel from "react-snapchat-pixel";
import sha256 from "sha256";
import axios from "axios";
import MobileOrderType from "./components/MobileOrderType";
import ToggleProduct from "../ToggleProduct";
import MobileFooter from "./components/MobileFooter";
import Drawer from "./components/Drawer";
import NewSideBar from "../NewSideBar";
import NewCommonNavbar from "../NewCommonNavbar";
import ListGridView from "../ListGridView";
import VendorDesktopLogo from "../VendorDesktopLogo/VendorDesktopLogo";
import HomepageDesign from "../InterNationalDelivery/HomepageDesign";
import { getOrCreateUserDetails } from "../APIS/userApi";
import { tele } from "../../services/constants";
import { changeArea, getScheduleTime } from "../APIS/areaAndTimesAPi";
import BottomDrawer from "../BottomModal/BottomDrawer";

function HomePage({ loading, toggleView, setToggleView, installable, width }) {
  const [categoryopen, setcategoryopen] = useState(false);
  const [categorynow, setcategorynow] = useState("");
  const details = useContext(VendorContext);
  const [searchItems, setSearchItems] = useState([]);
  const vendorSlug = useContext(VendorSlugContext);
  const [searchLoading, setSearchLoading] = useState(false);
  const { search, setSearch } = useContext(SearchContext);
  const { cart } = useContext(CartContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const [burger, setBurger] = useState(false);
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const [page, setPage] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [hasMore, setHasMore] = useState(0);
  const [customHeight, setCustomHeight] = useState(0);
  const { internationalDelivery } = useContext(InterNationalContext);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const { addressDetails, setAddressDetails } = useContext(ContactContext);
  const { contactDetails, setContactDetails } = useContext(ContactContext);

  const handleCountryChange = (code) => {};

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();
    if (
      details?.vendor?.fb_pixel_code &&
      details?.vendor?.fb_pixel_code != ""
    ) {
      ReactPixel.init(
        details?.vendor?.fb_pixel_code,
        {},
        { autoConfig: true, debug: false }
      );
      ReactPixel.pageView();
      ReactPixel.trackCustom("HomePage");
      const time = Date.now();
      const sec = Math.round(time / 1000);
      if (vendorSlug == "mijana-restaurant-and-café") {
        axios
          .post(
            `https://graph.facebook.com/v15.0/${details?.vendor?.fb_pixel_code}/events?access_token=EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB`,
            {
              data: [
                {
                  action_source: "website",
                  event_name: "HomePage",
                  event_time: sec,
                  user_data: {
                    client_user_agent:
                      "Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Mobile/15E148 Safari/604.1",
                    em: [sha256(details?.vendor?.support_mail)],
                  },
                },
              ],
            }
          )
          .then((res) => {})
          .catch((e) => console.log(e));
      }
    }
  }, []);

  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isPageLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isPageLoading, hasMore]
  );

  useEffect(() => {
    if (burger) {
      const sidediv = document.getElementById("navbar-id");
      if (sidediv) {
        setCustomHeight(sidediv.offsetHeight);
      }
    }
  }, [burger]);

  return (
    <div className="container-fluid">
      <div
        className="row"
        style={
          !burger &&
          window.innerWidth < 992 &&
          details?.vendor &&
          userDetails?.address?.length > 0 &&
          areaDetails?.data?.governarate &&
          !addressDetails?.id &&
          (details?.vendor?.international_delivery === "3" ||
            details?.vendor?.international_delivery === "" ||
            internationalDelivery.delivery_country.toLowerCase() ===
              "kuwait") &&
          areaDetails?.type !== "pickup"
            ? { height: "100vh", overflow: "hidden", position: "relative" }
            : {}
        }
      >
        {!burger || window.innerWidth > 992 ? (
          <div
            className={`col-lg-4 col-md-12 order-md-2 order-sm-2 order-lg-1 order-2 small-col p-0 ${
              cart?.cartCount ? "padding-to-footcart" : "padding-to-foot"
            }`}
            style={
              burger && customHeight
                ? {
                    height: `${customHeight}px`,
                    overflow: "hidden",
                    position: "relative",
                  }
                : details?.vendor &&
                  userDetails?.address?.length > 0 &&
                  areaDetails?.data?.governarate &&
                  !addressDetails?.id &&
                  (details?.vendor?.international_delivery === "3" ||
                    details?.vendor?.international_delivery === "" ||
                    internationalDelivery.delivery_country.toLowerCase() ===
                      "kuwait") &&
                  areaDetails?.type !== "pickup"
                ? { height: "100vh", overflow: "hidden", position: "relative" }
                : {}
            }
          >
            {window.innerWidth > 992 ? (
              <NewCommonNavbar
                burger={burger}
                setBurger={setBurger}
                language={language}
                setLanguage={setLanguage}
                details={details}
              />
            ) : null}
            {burger && window.innerWidth > 992 && (
              <NewSideBar setBurger={setBurger} />
            )}
            <div
              style={{
                display: window.innerWidth > 992 ? "block" : "none",
                padding: "0 6px",
                marginTop: "25px",
              }}
            >
              <VendorDesktopLogo />
            </div>
            {details?.vendor?.international_delivery === "3" ||
            details?.vendor?.international_delivery === "" ? null : (
              <div style={{ marginTop: "0px" }}>
                <HomepageDesign handleCountryChange={handleCountryChange} />
              </div>
            )}
            {internationalDelivery.delivery_country_code.toUpperCase() ===
            "KW" ? (
              <div
                style={{
                  marginTop:
                    details?.vendor?.home_page_type !== "17" &&
                    details?.vendor?.international_delivery === "3" &&
                    details?.vendor?.international_delivery === ""
                      ? "25px"
                      : "0px",
                }}
              >
                {details?.vendor?.home_page_type === "18" &&
                window.innerWidth < 992 ? null : (
                  <>
                    {details?.vendor?.home_page_type !== "17" ? (
                      <>
                        {details.vendor.vendors_id !== "1250" ? (
                          <OrderType></OrderType>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {details.vendor.vendors_id !== "1250" ? (
                          <MobileOrderType></MobileOrderType>
                        ) : null}
                      </>
                    )}
                    <SearchBar
                      setSearchLoading={setSearchLoading}
                      searchItems={searchItems}
                      setSearchItems={setSearchItems}
                      page={page}
                      setIsPageLoading={setIsPageLoading}
                      setHasMore={setHasMore}
                      hasMore={hasMore}
                      setPage={setPage}
                    ></SearchBar>
                    {details?.vendor?.custom_message != "" && search == "" ? (
                      <div
                        className="shop-closed"
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "17px",
                          border: "none",
                          paddingTop: 0,
                        }}
                      >
                        <div className="vendor-message">
                          {language == "ltr"
                            ? details?.vendor?.custom_message
                            : details?.vendor?.custom_message_ar}
                        </div>
                      </div>
                    ) : null}
                    {details?.vendor?.home_page_type != 14 &&
                      (areaDetails?.data?.branch &&
                      (areaDetails?.branchForArea?.start > moment() ||
                        moment() > areaDetails?.branchForArea?.end) ? (
                        <div className="shop-closed">
                          {areaDetails?.type == "pickup"
                            ? language == "ltr"
                              ? `Store is closed now, we are open ${areaDetails?.branchForArea?.start
                                  ?.clone()
                                  .add(
                                    areaDetails?.branchForArea?.start > moment()
                                      ? 0
                                      : 1,
                                    "d"
                                  )
                                  .locale("en")
                                  .format("DD MMMM, yyyy hh:mm A")}`
                              : `المتجر مغلق حاليا, بأمكانك زيارتنا في ${
                                  areaDetails?.branchForArea?.start
                                    ?.clone()
                                    .add(
                                      areaDetails?.branchForArea?.start >
                                        moment()
                                        ? 0
                                        : 1,
                                      "d"
                                    )
                                    .locale("en")
                                    .format("DD ") +
                                  areaDetails?.branchForArea?.start
                                    ?.clone()
                                    .add(
                                      areaDetails?.branchForArea?.start >
                                        moment()
                                        ? 0
                                        : 1,
                                      "d"
                                    )
                                    .locale("ar-sa")
                                    .format("MMMM") +
                                  areaDetails?.branchForArea?.start
                                    ?.clone()
                                    .add(
                                      areaDetails?.branchForArea?.start >
                                        moment()
                                        ? 0
                                        : 1,
                                      "d"
                                    )
                                    .locale("en")
                                    .format(", yyyy hh:mm ") +
                                  areaDetails?.branchForArea?.start
                                    ?.clone()
                                    .add(
                                      areaDetails?.branchForArea?.start >
                                        moment()
                                        ? 0
                                        : 1,
                                      "d"
                                    )
                                    .locale("ar-sa")
                                    .format("A")
                                }`
                            : areaDetails?.data?.branch?.filter(
                                (k) => k?.id == areaDetails?.branchForArea?.id
                              )[0]?.on_shop_close_purchase == 1
                            ? language == "ltr"
                              ? "The shop is closed, can't place any order"
                              : "المتجر مغلق حاليا"
                            : language == "ltr"
                            ? "Store already closed, you order will be placed and delivered next working day"
                            : "المتجر مغلق حاليا, سيتم توصيل طلبك في يوم العمل التالي"}
                        </div>
                      ) : null)}
                  </>
                )}
              </div>
            ) : null}

            {search != "" ? (
              searchItems?.length != 0 || searchLoading ? (
                <>
                  <ProductsList
                    loading={searchLoading}
                    lastBookElementRef={lastBookElementRef}
                    products={searchItems}
                  ></ProductsList>
                  {isPageLoading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "10px 0",
                      }}
                    >
                      <ClipLoader
                        size={40}
                        color={details?.vendor?.vendor_color}
                      ></ClipLoader>
                    </div>
                  )}
                </>
              ) : (
                <SearchNone></SearchNone>
              )
            ) : loading == false ? (
              <>
                {details?.vendor?.home_page_type == 14 && (
                  <div className="toggle-flex">
                    {areaDetails?.data?.branch &&
                      (areaDetails?.branchForArea?.start > moment() ||
                      moment() > areaDetails?.branchForArea?.end ? (
                        <div
                          className="shop-closed"
                          style={{
                            width: "auto",
                            paddingRight: 0,
                            paddingLeft: 0,
                          }}
                        >
                          {areaDetails?.type == "pickup"
                            ? language == "ltr"
                              ? `Store is closed now, we are open ${areaDetails?.branchForArea?.start
                                  ?.clone()
                                  .add(
                                    areaDetails?.branchForArea?.start > moment()
                                      ? 0
                                      : 1,
                                    "d"
                                  )
                                  .locale("en")
                                  .format("DD MMMM, yyyy hh:mm A")}`
                              : `المتجر مغلق حاليا, بأمكانك زيارتنا في ${
                                  areaDetails?.branchForArea?.start
                                    ?.clone()
                                    .add(
                                      areaDetails?.branchForArea?.start >
                                        moment()
                                        ? 0
                                        : 1,
                                      "d"
                                    )
                                    .locale("en")
                                    .format("DD ") +
                                  areaDetails?.branchForArea?.start
                                    ?.clone()
                                    .add(
                                      areaDetails?.branchForArea?.start >
                                        moment()
                                        ? 0
                                        : 1,
                                      "d"
                                    )
                                    .locale("ar-sa")
                                    .format("MMMM") +
                                  areaDetails?.branchForArea?.start
                                    ?.clone()
                                    .add(
                                      areaDetails?.branchForArea?.start >
                                        moment()
                                        ? 0
                                        : 1,
                                      "d"
                                    )
                                    .locale("en")
                                    .format(", yyyy hh:mm ") +
                                  areaDetails?.branchForArea?.start
                                    ?.clone()
                                    .add(
                                      areaDetails?.branchForArea?.start >
                                        moment()
                                        ? 0
                                        : 1,
                                      "d"
                                    )
                                    .locale("ar-sa")
                                    .format("A")
                                }`
                            : areaDetails?.data?.branch?.filter(
                                (k) => k?.id == areaDetails?.branchForArea?.id
                              )[0]?.on_shop_close_purchase == 1
                            ? language == "ltr"
                              ? "The shop is closed, can't place any order"
                              : "المتجر مغلق حاليا"
                            : language == "ltr"
                            ? "Store already closed, you order will be placed and delivered next working day"
                            : "المتجر مغلق حاليا, سيتم توصيل طلبك في يوم العمل التالي"}
                        </div>
                      ) : (
                        <div
                          className="shop-closed"
                          style={{
                            width: "80%",
                            wordBreak: "break-all",
                            border: "none",
                          }}
                        >
                          &nbsp;
                        </div>
                      ))}
                    <span
                      className={`toggle-button toggle-active`}
                      onClick={() =>
                        setToggleView((toggle) => (toggle == 1 ? 0 : 1))
                      }
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          fontSize: language == "ltr" ? 13 : 15,
                          width: 100,
                        }}
                      >
                        {language == "ltr" ? "Change View" : "تغيير العرض"}
                        &nbsp;&nbsp;
                      </span>
                      {toggleView == 1 ? (
                        <i className="fa fa-th-large" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-th-list" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                )}
                {(details?.vendor?.home_page_type == 10 ||
                  details?.vendor?.home_page_type == 13 ||
                  details?.vendor?.home_page_type == 16) && (
                  <CategoryContainer
                    categories={details?.categories}
                  ></CategoryContainer>
                )}
                {details?.vendor?.home_page_type == 11 && (
                  <CategoryListView
                    categories={details?.categories}
                    setcategoryopen={setcategoryopen}
                    setcategorynow={setcategorynow}
                    categorynow={categorynow}
                  ></CategoryListView>
                )}
                {details?.vendor?.home_page_type == 12 && (
                  <ScrollView
                    categories={details?.categories}
                    setcategoryopen={setcategoryopen}
                  ></ScrollView>
                )}
                {details?.vendor?.home_page_type == 14 && (
                  <div>
                    <div style={toggleView == 0 ? {} : { overflow: "hidden" }}>
                      <div
                        style={{
                          position: "relative",
                          transition: "1s",
                          left: toggleView == 0 ? 0 : "-100%",
                        }}
                      >
                        <div
                          style={{
                            display: toggleView == 0 ? "block" : "none",
                          }}
                        >
                          <ToggleCategory
                            categories={details?.categories}
                            categorynow={categorynow}
                            setcategorynow={setcategorynow}
                          ></ToggleCategory>
                        </div>
                      </div>

                      <div
                        style={{
                          position: "relative",
                          transition: "1s",
                          right: toggleView != 0 ? 0 : "-100%",
                        }}
                      >
                        <div
                          style={{
                            display: toggleView != 0 ? "block" : "none",
                          }}
                        >
                          <CategoryContainer
                            categories={details?.categories}
                          ></CategoryContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {details?.vendor?.home_page_type == 15 && (
                  <CategoryDrop
                    setcategoryopen={setcategoryopen}
                    categories={details?.categories}
                    categorynow={categorynow}
                  ></CategoryDrop>
                )}
                {details?.vendor?.home_page_type == 19 && <ListGridView />}
                {details?.vendor?.home_page_type == 17 && (
                  <div>
                    <ToggleProduct
                      details={details}
                      toggleView={toggleView}
                      setToggleView={setToggleView}
                      categories={details?.categories}
                      categorynow={categorynow}
                      setcategorynow={setcategorynow}
                      setcategoryopen={setcategoryopen}
                    ></ToggleProduct>
                  </div>
                )}
              </>
            ) : (
              <div className="shine-loader">
                <div
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: "100%",
                  }}
                ></div>
              </div>
            )}
            {categoryopen && (
              <CategorySelect
                categories={details?.categories}
                setcategoryopen={setcategoryopen}
                categorynow={categorynow}
                setcategorynow={setcategorynow}
                toggleView={toggleView}
              ></CategorySelect>
            )}
            {details?.vendor?.home_page_type == "18" ? (
              cart?.cartCount ? (
                <Drawer></Drawer>
              ) : null
            ) : cart?.cartCount ? (
              <ReviewBar></ReviewBar>
            ) : null}
            <Footer text={"page-footer"}></Footer>
            {window.innerWidth < 992 && details?.vendor?.slug_type !== 1 ? (
              <MobileFooter></MobileFooter>
            ) : null}
            {details?.vendor &&
            userDetails?.address?.length > 0 &&
            areaDetails?.data?.governarate &&
            window.innerWidth > 992 &&
            !addressDetails?.id &&
            (details?.vendor?.international_delivery === "3" ||
              details?.vendor?.international_delivery === "" ||
              internationalDelivery.delivery_country.toLowerCase() ===
                "kuwait") &&
            areaDetails?.type !== "pickup" ? (
              <BottomDrawer type="home" />
            ) : null}
          </div>
        ) : null}
        <div
          className={`col-lg-8 col-md-12 order-md-1 order-lg-2 order-sm-1 order-1 large-col p-0`}
        >
          <MobileNavigationBar
            installable={installable}
            width={width}
            burger={burger}
            setBurger={setBurger}
          ></MobileNavigationBar>
          {!burger || window.innerWidth > 992 ? (
            <>
              <div className="owl-bra">
                <OwlCarousel
                  dir="ltr"
                  items={1}
                  className="owl-theme"
                  loop={!(details?.vendor?.banner_images?.length == 1)}
                  // mouseDrag={!(details?.vendor?.banner_images?.length == 1)}
                  mouseDrag={false}
                  autoplay={!(details?.vendor?.banner_images?.length == 1)}
                  dots={false}
                  animateIn="fadeIn"
                  animateOut="fadeOut"
                >
                  {details?.vendor?.banner_images?.map((pic, i) => (
                    <div
                      className={`${
                        details?.vendor?.set_gradient_background == 1
                          ? "gradient"
                          : ""
                      }`}
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (pic.link) {
                          window.location.assign(pic.link);
                        }
                      }}
                    >
                      <img className={`owl-banner-pic`} src={`${pic?.image}`} />
                    </div>
                  ))}
                </OwlCarousel>
              </div>
              {window.innerWidth < 991 ? (
                <MobileVendorLogo></MobileVendorLogo>
              ) : null}
              {/* <div className="logo-container">
                {details?.vendor && details?.vendor?.english_new_background && (
                  <img
                    src={
                      language == "ltr"
                        ? details?.vendor?.english_new_background
                        : details?.vendor?.arabic_new_background
                    }
                    className="img-fluid right-big-logo"
                    alt="payzah-logo"
                  ></img>
                )}
                <p className="logo-name mt-4">
                  {language == "ltr"
                    ? details?.vendor?.name
                    : details?.vendor?.name_ar}
                </p>
                <p className="logo-detail mt-2">
                  {language == "ltr"
                    ? details?.vendor?.slogan
                    : details?.vendor?.slogan_ar}
                </p>
              </div>{" "} */}
            </>
          ) : null}
          {burger && window.innerWidth < 992 && (
            <NewSideBar setBurger={setBurger} />
          )}
        </div>
        {details?.vendor &&
        userDetails?.address?.length > 0 &&
        areaDetails?.data?.governarate &&
        window.innerWidth < 992 &&
        !addressDetails?.id &&
        (details?.vendor?.international_delivery === "3" ||
          details?.vendor?.international_delivery === "" ||
          internationalDelivery.delivery_country.toLowerCase() === "kuwait") &&
        areaDetails?.type !== "pickup" ? (
          <BottomDrawer type="home" />
        ) : null}
      </div>
      <WhatsappSticky />
    </div>
  );
}

export default HomePage;
