import React, { useContext } from 'react'
import { LanguageContext } from '../../../App'

const Title = ({ englishTitle, arabicTitle }) => {
    const { language } = useContext(LanguageContext)

    return (
        <div className='title-div'>
            {language === "ltr" ? englishTitle : arabicTitle}
        </div>
    )
}

export default Title