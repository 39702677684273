import React, { useContext, useEffect, useState } from "react";
import {
  AreaContext,
  CartContext,
  ContactContext,
  InterNationalContext,
  LanguageContext,
  UserContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import CommonHeader from "./Components/CommonHeader";
import "./checkOut.css";
import "./rtlCheckout.css";
import NewContactDetails from "./Components/NewContactDetails";
import { Link, useHistory } from "react-router-dom";
import PickupContainer from "./Components/PickupContainer";
import moment from "moment";
import ContactInfo from "./Components/ContactInfo";
import OtpVerification from "./Components/OtpVerification";
import {
  getOrCreateUserDetails,
  GetUserDetails,
  RegisterUser,
  updateUserDetails,
  verifyUserOTP,
} from "../APIS/userApi";
import { tele } from "../../services/constants";
import { changeArea, getScheduleTime } from "../APIS/areaAndTimesAPi";
import Spinner from "../../SVGs/Spinner";
import toast, { Toaster } from "react-hot-toast";
import BackComponent from "./Components/BackComponent";

const NewCheckOutPage = () => {
  const details = useContext(VendorContext);
  const [pickupError, setPickupError] = useState({
    modelError: "",
    colorError: "",
  });
  const { language } = useContext(LanguageContext);
  const [stepper, setStepper] = useState(0);
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const { userDetails, setUserDetails, resetUserDetails } =
    useContext(UserContext);
  const history = useHistory();
  const { cart } = useContext(CartContext);
  const {
    contactDetails,
    setContactDetails,
    addressDetails,
    setAddressDetails,
  } = useContext(ContactContext);
  const { internationalDelivery } = useContext(InterNationalContext);
  const vendorSlug = useContext(VendorSlugContext);

  const [errorContactDetails, setErrorContactDetails] = useState({
    emailError: false,
    emailErrorMessage: "",
    emailErrorMessagear: "",
    nameError: false,
    nameErrorMessage: "",
    nameErrorMessagear: "",
    phoneError: false,
    phoneErrorMessage: "",
    phoneErrorMessagear: "",
  });

  const [showNameEmailFields, setShowNameEmailFields] = useState(false);
  const [openOtpPage, setOpenOtpPage] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showGuestUser, setShowGuestUser] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGuestNext = () => {
    if (stepper === 0) {
      let email = emailValidation(contactDetails.email);
      let phone = phoneValidation(contactDetails.phone, true);
      let name = nameValidation(contactDetails.name, true);
      if (!email && !phone && !name) {
        let data = {
          email: contactDetails.email,
          phone: contactDetails.phone,
          name: contactDetails.name,
          code: contactDetails.phoneCode,
          expire: new Date().getTime(),
        };
        if (data) {
          localStorage.setItem("contactInfo", JSON.stringify(data));
        }
        if (areaDetails?.type === "pickup") {
          if (vendorSlug === "alawael-bilingual-school") {
            history.push("/checkout");
          } else {
            setStepper(1);
          }
        } else if (
          (details?.vendor?.international_delivery !== "3" &&
            details?.vendor?.international_delivery !== "") ||
          internationalDelivery.delivery_country_code.toUpperCase() !== "KW"
        ) {
          if (localStorage.getItem("newPath") == "review") {
            history.push("/delivery-address");
          } else {
            history.push("/");
          }
        } else {
          history.push("/delivery-address");
        }
      }
    } else if (stepper === 1) {
      if (contactDetails.model !== "" && contactDetails.color !== "") {
        history.push("/checkout");
      } else {
        if (contactDetails.model == "" && contactDetails.color !== "") {
          setPickupError({ ...pickupError, modelError: true });
        } else if (contactDetails.model !== "" && contactDetails.color == "") {
          setPickupError({ ...pickupError, colorError: true });
        } else {
          setPickupError({
            ...pickupError,
            colorError: true,
            modelError: true,
          });
        }
      }
    }
  };

  const handleNext = async () => {
    if (stepper === 0) {
      if (openOtpPage) {
        setLoading(true);

        const response = await verifyUserOTP({
          country_code: `+${tele[contactDetails.phoneCode]}`,
          phone_number: contactDetails.phone,
          verification_code: otp,
          user_id: localStorage.getItem("id"),
          vendor_ecom_id: details?.vendor?.ecommerce_vendor_id,
          language: language,
        });

        setLoading(false);

        if (response?.status) {
          localStorage.setItem("token", response?.jwt_token);
          setOpenOtpPage(false);
          const userReponse = await GetUserDetails({
            vendor_id: details?.vendor.vendors_id,
            sendSMS: false,
            country_code: `+${tele[contactDetails.phoneCode]}`,
            phone_number: contactDetails.phone,
            jwt_token: response?.jwt_token,
            user_id: localStorage.getItem("id"),
            language: language,
          });
          if (userReponse?.status) {
            if (
              userReponse?.data?.name &&
              userReponse?.data?.email &&
              userReponse?.data?.phone
            ) {
              setShowGuestUser(false);
              let data = {
                name: userReponse?.data?.name,
                phone: userReponse?.data?.phone,
                email: userReponse?.data?.email,
                code:
                  Object.keys(tele).find(
                    (ele) =>
                      tele[ele] ==
                      userReponse?.data?.country_code.replace("+", "")
                  ) ?? "KW",
                expire: new Date().getTime(),
              };
              if (data) {
                localStorage.setItem("contactInfo", JSON.stringify(data));
              }
              setUserDetails({ ...userReponse?.data });
              setContactDetails({
                ...contactDetails,
                name: userReponse?.data?.name,
                email: userReponse?.data?.email,
                phone: userReponse?.data?.phone,
                phoneCode:
                  Object.keys(tele).find(
                    (ele) =>
                      tele[ele] ==
                      userReponse?.data?.country_code.replace("+", "")
                  ) ?? "KW",
              });
              if (localStorage.getItem("newPath") == "review") {
                if (areaDetails?.type === "pickup") {
                  setStepper(1);
                } else if (
                  areaDetails?.type === "delivery" &&
                  userReponse?.data?.address?.length == 0
                ) {
                  history.push("/delivery-address");
                } else if (
                  (details?.vendor?.international_delivery !== "3" &&
                    details?.vendor?.international_delivery !== "") ||
                  internationalDelivery.delivery_country_code.toUpperCase() !==
                    "KW"
                ) {
                  history.push("/delivery-address");
                } else {
                  history.push("/");
                }
              } else {
                history.push("/");
              }
            } else {
              setShowNameEmailFields(true);
              setShowGuestUser(false);
            }
          } else {
            toast.error(userReponse?.message);
            localStorage.removeItem("token");
            localStorage.removeItem("contactInfo");
            resetUserDetails();
            history.push("/");
          }
        } else {
          toast.error(response?.message);
        }
      } else if (showNameEmailFields) {
        let name = nameValidation(contactDetails.name);
        let phone = phoneValidation(contactDetails.phone, true);
        let email = emailValidation(contactDetails.email, true);

        if (!name && !email && !phone) {
          setLoading(true);

          const response = await updateUserDetails({
            vendor_id: details?.vendor.vendors_id,
            country_code: `+${tele[contactDetails.phoneCode]}`,
            phone_number: contactDetails.phone,
            full_name: contactDetails.name,
            email: contactDetails.email,
            jwt_token: localStorage.getItem("token"),
            user_id: localStorage.getItem("id"),
            language: language,
          });

          setLoading(false);

          if (response?.status) {
            const userReponse = await GetUserDetails({
              vendor_id: details?.vendor.vendors_id,
              sendSMS: false,
              country_code: `+${tele[contactDetails.phoneCode]}`,
              phone_number: contactDetails.phone,
              jwt_token: localStorage.getItem("token"),
              user_id: localStorage.getItem("id"),
              language: language,
            });
            if (userReponse?.status) {
              let data = {
                name: userReponse?.data?.name,
                phone: userReponse?.data?.phone,
                email: userReponse?.data?.email,
                code:
                  Object.keys(tele).find(
                    (ele) =>
                      tele[ele] ==
                      userReponse?.data?.country_code.replace("+", "")
                  ) ?? "KW",
                expire: new Date().getTime(),
              };
              if (data) {
                localStorage.setItem("contactInfo", JSON.stringify(data));
              }
              setUserDetails({ ...userReponse?.data });
              setContactDetails({
                ...contactDetails,
                name: userReponse?.data?.name,
                email: userReponse?.data?.email,
                phone: userReponse?.data?.phone,
                phoneCode:
                  Object.keys(tele).find(
                    (ele) =>
                      tele[ele] ==
                      userReponse?.data?.country_code.replace("+", "")
                  ) ?? "KW",
              });
              if (localStorage.getItem("newPath") == "review") {
                if (areaDetails?.type === "pickup") {
                  setStepper(1);
                } else if (
                  areaDetails?.type === "delivery" &&
                  userDetails?.address?.length == 0
                ) {
                  history.push("/delivery-address");
                } else if (
                  (details?.vendor?.international_delivery !== "3" &&
                    details?.vendor?.international_delivery !== "") ||
                  internationalDelivery.delivery_country_code.toUpperCase() !==
                    "KW"
                ) {
                  history.push("/delivery-address");
                } else {
                  history.push("/");
                }
              } else {
                history.push("/");
              }
            } else {
              toast.error(response?.message);
              localStorage.removeItem("token");
              localStorage.removeItem("contactInfo");
              resetUserDetails();
              history.push("/");
            }
          } else {
            toast.error(response?.message);
          }
        } else {
          console.log("validation failed");
        }
      } else {
        const contactInfo = JSON.parse(
          localStorage.getItem("contactInfo") || "{}"
        );
        let phone = phoneValidation(
          contactDetails.phone,
          details?.vendor?.checkout_method === "2"
        );
        let email = emailValidation(
          contactDetails.email,
          details?.vendor?.checkout_method === "1"
        );

        if (!phone && !email) {
          let data = {
            phone: contactDetails.phone,
            email: contactDetails.email,
            code: contactDetails.phoneCode,
            expire: new Date().getTime(),
          };
          if (data) {
            localStorage.setItem("contactInfo", JSON.stringify(data));
          }
          if (
            areaDetails?.type === "pickup" &&
            contactDetails.name &&
            nameValidation(contactDetails.name)
          ) {
            setStepper(1);
          } else {
            setLoading(true);
            const response = await RegisterUser({
              vendor_id: details?.vendor.vendors_id,
              vendor_ecom_id: details?.vendor?.ecommerce_vendor_id,
              sendSMS: false,
              country_code: `+${tele[contactDetails.phoneCode]}`,
              phone_number: contactDetails.phone,
              email: contactDetails.email,
              language: language,
            });
            setLoading(false);

            if (response?.data?.is_otp_sent) {
              localStorage.setItem("id", response?.data?.id);
              toast.success(response?.message);
              setOtpSent(true);
              setOpenOtpPage(true);
            } else {
              toast.error(response?.message);
            }
          }
        }
      }
    } else if (stepper === 1) {
      if (contactDetails.model !== "" && contactDetails.color !== "") {
        localStorage.removeItem("newPath");
        history.push("/checkout");
      } else {
        if (contactDetails.model == "" && contactDetails.color !== "") {
          setPickupError({ ...pickupError, modelError: true });
        } else if (contactDetails.model !== "" && contactDetails.color == "") {
          setPickupError({ ...pickupError, colorError: true });
        } else {
          setPickupError({
            ...pickupError,
            colorError: true,
            modelError: true,
          });
        }
      }
    }
  };

  const nameValidation = (value) => {
    let pattern =
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ ]*$/;
    if (value == "") {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "This field is compulsory",
        nameErrorMessagear: "مطلوب ملء هذا الحقل",
        nameError: true,
      }));
      return true;
    } else if (value.match(pattern)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "",
        nameError: false,
        nameErrorMessagear: "",
      }));
      return false;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "Only alphabets are allowed",
        nameError: true,
        nameErrorMessagear: "يسمح فقط الحروف الهجائية",
      }));
      return true;
    }
  };

  const phoneValidation = (value, isCompulsory) => {
    let pattern = /^[0-9]+$/;
    let kwpattern = /^[124965]\d+$/;
    if (value === "" && isCompulsory) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "This field is compulsory",
        phoneError: true,
        phoneErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else if (value && value.length < 8) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Please enter at least 8 characters",
        phoneError: true,
        phoneErrorMessagear: "الرجاء إدخال 8 أرقام",
      }));
      return true;
    } else if (value && !value.match(pattern)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Only numbers allowed",
        phoneError: true,
        phoneErrorMessagear: "مسموح بالأرقام فقط",
      }));
      return true;
    } else if (
      value &&
      contactDetails?.phoneCode == "KW" &&
      !value.match(kwpattern)
    ) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Enter a valid phone number",
        phoneError: true,
        phoneErrorMessagear: "أدخل رقم هاتف صالح",
      }));
      return true;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "",
        phoneError: false,
        phoneErrorMessagear: "",
      }));
      return false;
    }
  };

  const emailValidation = (value, isCompulsory) => {
    let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value === "" && isCompulsory) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        emailErrorMessage: "This field is compulsory",
        emailError: true,
        emailErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else if (value && !pattern.test(value)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        emailErrorMessage: "Enter a valid email",
        emailError: true,
        emailErrorMessagear: "البريد الالكتروني غير صحيح",
      }));
      return true;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        emailErrorMessage: "",
        emailError: false,
        emailErrorMessagear: "",
      }));
      return false;
    }
  };

  // useEffect(() => {
  //   if (JSON.parse(localStorage.getItem("contactInfo"))) {
  //     const contactInfo = JSON.parse(localStorage.getItem("contactInfo"));
  //     let diff = "";
  //     if (contactInfo.expire) {
  //       const currentTime = new Date().getTime();
  //       diff = currentTime - parseInt(contactInfo.expire, 10);
  //     }
  //     if (diff > 30 * 60 * 1000) {
  //       localStorage.removeItem("contactInfo");
  //     } else {
  //       setContactDetails({
  //         ...contactDetails,
  //         email: contactInfo.email,
  //         name: contactInfo.name,
  //         phone: contactInfo.phone,
  //         phoneCode: contactInfo.code,
  //       });
  //     }
  //   }
  // }, []);
  useEffect(() => {
    if (userDetails?.id && areaDetails?.type == "pickup") {
      setStepper(1);
    }
  }, [userDetails]);

  return (
    <>
      <div className="holder-container">
        <div className="toasterDiv">
          <Toaster position="top-left" reverseOrder={false} />
        </div>
        <div
          onClick={() => {
            if (openOtpPage) {
              setOpenOtpPage(false);
            } else {
              if (userDetails?.is_guest) {
                setUserDetails((prev) => ({ ...prev, is_guest: false }));
              } else {
                history.push("/");
              }
            }
          }}
        >
          <BackComponent />
        </div>
        <div>
          <CommonHeader
            englishHeader={
              showNameEmailFields
                ? "Profile"
                : stepper === 0
                ? userDetails?.is_guest
                  ? "Contact Details"
                  : "Login"
                : "Checkout"
            }
            arabicHeader={
              showNameEmailFields
                ? "حساب تعريفي"
                : stepper === 0
                ? userDetails?.is_guest
                  ? "ارقام التواصل"
                  : "تسجيل الدخول"
                : "تفاصيل الإتصال"
            }
            fontWeight={600}
          />
        </div>
        {/* {stepper === 0 && userDetails?.is_guest ? (
          <NewContactDetails
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            errorContactDetails={errorContactDetails}
            setErrorContactDetails={setErrorContactDetails}
            nameValidation={nameValidation}
            phoneValidation={phoneValidation}
            emailValidation={emailValidation}
            internationalDelivery={internationalDelivery}
          />
        ) : null} */}
        {/* Imp code don't remove it */}
        {/* {stepper === 0 &&
        !openOtpPage &&
        (details?.vendor?.checkout_method == "1" ||
          details?.vendor?.checkout_method == "2") ? (
          <ContactInfo
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            internationalDelivery={internationalDelivery}
            errorContactDetails={errorContactDetails}
            showNameEmailFields={showNameEmailFields}
          />
        ) : null} */}
        {stepper === 0 ? (
          userDetails?.is_guest ? (
            <NewContactDetails
              contactDetails={contactDetails}
              setContactDetails={setContactDetails}
              errorContactDetails={errorContactDetails}
              setErrorContactDetails={setErrorContactDetails}
              nameValidation={nameValidation}
              phoneValidation={phoneValidation}
              emailValidation={emailValidation}
              internationalDelivery={internationalDelivery}
            />
          ) : !openOtpPage ? (
            <ContactInfo
              contactDetails={contactDetails}
              setContactDetails={setContactDetails}
              internationalDelivery={internationalDelivery}
              errorContactDetails={errorContactDetails}
              showNameEmailFields={showNameEmailFields}
              showGuestUser={showGuestUser}
            />
          ) : null
        ) : null}
        {openOtpPage ? (
          <OtpVerification
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            openOtpPage={openOtpPage}
            setOpenOtpPage={setOpenOtpPage}
            otpSent={otpSent}
            setOtpSent={setOtpSent}
            otp={otp}
            setOtp={setOtp}
          />
        ) : null}

        {stepper === 1 ? (
          <PickupContainer
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            pickupError={pickupError}
          />
        ) : null}
      </div>
      <div
        className={`contact-details-bottom-button contact-details-mobile-button ${
          details?.vendor?.home_page_type === "18" && "fashion-theme"
        }`}
      >
        <Link
          className="contact-details-next-button"
          onClick={() => {
            if (userDetails?.is_guest) {
              handleGuestNext();
            } else {
              handleNext();
            }
          }}
        >
          {language === "ltr" ? "Next" : "متابعة"}
        </Link>
      </div>

      {loading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "8",
          }}
          className="order-spinner-background"
        >
          <Spinner
            height="50px"
            color={details?.vendor?.vendor_color}
            size="6px"
          />
        </div>
      )}
    </>
  );
};

export default NewCheckOutPage;
