import React, { useContext, useEffect, useState } from "react";
import "./NewOrderStatus.css";
import {
  AreaContext,
  CartContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
  ContactContext,
  UserContext,
} from "../../App";
import { API_URL } from "../../services/constants";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import SnapPixel from "react-snapchat-pixel";
import TiktokPixel from "tiktok-pixel";
import { purchaseTag } from "../../purchasetag";
import { applyReorder, tryAgainOrder } from "../APIS/checkOutApi";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CommonDeliveryStatus from "./Components/CommonDeliveryStatus";
import DeliveryMapStatus from "./Components/DeliveryMapStatus";
import CustomAccrodian from "./Components/CustomAccrodian";
import { AccrodianOrderDetails } from "./Components/AccrodianOrderDetails";
import AccordianPaymentDetails from "./Components/AccordianPaymentDetails";
import AccordianContactStore from "./Components/AccordianContactStore";
import Spinner from "../../SVGs/Spinner";
import TermsModal from "../TermsModal/TermsModal";
import LongButtonsModal from "./Components/LongButtonsModal";
import NewPaymentSelector from "../NewOrderDetailsPage/Components/NewPaymentSelector";
import moment from "moment";
import { changeArea, getScheduleTime } from "../APIS/areaAndTimesAPi";
// import ContactContext from "../../App";

const NewOrderStatuPage = () => {
  const details = useContext(VendorContext);
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState();
  const { language } = useContext(LanguageContext);
  const [paymentURL, setPaymentURL] = useState("#");
  const vendorSlug = useContext(VendorSlugContext);
  const [loading, setLoading] = useState(false);
  const [noOrderID, setNoOrderID] = useState(false);
  const [priceChange, setPriceChange] = useState(false);
  const { setCart, cart } = useContext(CartContext);
  const history = useHistory();
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const { userDetails } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);
  const { payment, setPayment, setAddressDetails } = useContext(ContactContext);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (vendorSlug != "")
      axios
        .post(
          `${API_URL}/get-order-details`,
          JSON.stringify({
            token: process.env.REACT_APP_TOKEN,
            vendor_slug: vendorSlug,
            vendor_id: details?.vendor?.vendors_id,
            ecommerce_vendor_id: details.vendor.ecommerce_vendor_id,
            order_number: id,
          })
        )
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            if (
              localStorage?.getItem("check") == 1 &&
              res.data.data?.payment_status == 1
            ) {
              if (details?.vendor?.fb_pixel_code != "")
                ReactPixel.track("Purchase", {
                  content_ids: res.data?.data?.cartItems?.map(
                    (k) => k?.product_slug
                  ),
                  value: res?.data?.data?.subTotal,
                  content_type: "product",
                  contents: res.data?.data?.cartItems?.map((k) => ({
                    id: k?.product_slug,
                    name: k?.english_name,
                    quantity: k?.quantity,
                    value: k?.original_price,
                  })),
                  currency: "KWD",
                });

              if (details?.vendor?.snap_pixel_code != "")
                SnapPixel.track("PURCHASE", {
                  price: res?.data?.data?.subTotal,
                  transaction_id: id,
                  currency: "KWD",
                });

              if (details?.vendor?.vendors_id === "132") {
                TiktokPixel.track("CompletePayment", {
                  content_type: "product_group",
                  contents: res.data?.data?.cartItems?.map((k) => ({
                    content_id: k?.product_slug,
                    content_type: "product",
                    quantity: k?.quantity,
                    content_name: k?.english_name,
                    price: k?.original_price,
                  })),
                  currency: "KWD",
                  value: res?.data?.data?.subTotal,
                });
              }

              if (
                details?.vendor?.google_tag_code != "" &&
                !/^GTM/.test(details?.vendor?.google_tag_code)
              )
                purchaseTag({
                  transaction_id: id,
                  items: res.data?.data?.cartItems?.map((k, l) => ({
                    id: k?.product_slug,
                    name: k?.english_name,
                    quantity: k?.quantity,
                    price: k?.original_price,
                    list_position: l + 1,
                  })),
                  currency: "KWD",
                  value: res?.data?.data?.subTotal,
                  quantity: res.data.data?.delivery_charge,
                });
            }
          } else {
            setNoOrderID(true);
          }
          localStorage?.removeItem("check");
          setOrderDetails((products) => res.data.data);
        })
        .catch((e) => console.log(e));
  }, [details?.vendor, vendorSlug]);

  const getOrderDetailsAPI = async () => {
    if (vendorSlug != "") {
      axios
        .post(
          `${API_URL}/get-order-details`,
          JSON.stringify({
            token: process.env.REACT_APP_TOKEN,
            vendor_slug: vendorSlug,
            vendor_id: details?.vendor?.vendors_id,
            ecommerce_vendor_id: details.vendor.ecommerce_vendor_id,
            order_number: id,
          })
        )
        .then((res) => {
          if (res.data.status) {
            if (
              localStorage?.getItem("check") == 1 &&
              res.data.data?.payment_status == 1
            ) {
            }
          } else {
            setNoOrderID(true);
          }
          localStorage?.removeItem("check");
          setOrderDetails((products) => res.data.data);
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    if (vendorSlug != "") {
      getOrderDetailsAPI();
      const intervalId = setInterval(getOrderDetailsAPI, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [vendorSlug]);

  // useEffect(() => {
  //   if (vendorSlug != "")
  //     axios
  //       .post(
  //         `${API_URL}/order-tryagain`,
  //         JSON.stringify({
  //           token: process.env.REACT_APP_TOKEN,
  //           vendor_slug: vendorSlug,
  //           vendor_id: details?.vendor?.vendors_id,
  //           order_number: id,
  //           user_string: localStorage.getItem("userID"),
  //         })
  //       )
  //       .then((res) => {
  //         setPaymentURL((paymentURL) => res.data.data.payment_url);
  //       })
  //       .catch((e) => console.log(e));
  // }, [details?.vendor]);

  const accordianArray = [
    {
      english: "Order Details",
      arabic: "تفاصيل الطلب",
      component: <AccrodianOrderDetails orderDetails={orderDetails} />,
    },
    {
      english: "Payments Details",
      arabic: "تفاصيل المدفوعات",
      component: <AccordianPaymentDetails orderDetails={orderDetails} />,
    },
    {
      english: "Contact Store",
      arabic: "تواصل مع المتجر",
      component: <AccordianContactStore vendorData={details?.vendor} />,
    },
  ];

  const getUserCart = async () => {
    axios
      .post(
        `${API_URL}/get-user-cart`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_id: details?.vendor?.vendors_id,
          vendor_slug: vendorSlug,
          area_id: areaDetails?.area_id,
          user_string: localStorage.getItem("userID"),
        })
      )
      .then((res) => {
        if (!res.data.data?.cartCount == 0) {
          setCart(res.data.data);
          history.push("/");
        }
      })
      .catch((e) => console.log(e));
  };

  const handleReorder = async (is_proceed) => {
    // Call api for reorder
    setLoading(true);
    const user_string = localStorage.getItem("userID");
    const response = await applyReorder(
      id,
      is_proceed,
      user_string,
      vendorSlug,
      details?.vendor?.vendors_id
    );
    if (response.status) {
      if (response.data.is_success === 1) {
        setLoading(false);
        getUserCart();
      } else {
        setPriceChange(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handletryAgain = async () => {
    setLoading(true);
    const response = await tryAgainOrder(
      vendorSlug,
      details?.vendor?.vendors_id,
      id
    );
    if (response.status == true) {
      window.location.assign(response.data.payment_url);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   (async () => {
  //     if (userDetails?.address?.length) {
  //       const addressData =
  //         userDetails.address.filter((ele) => ele.is_primary)?.[0] ??
  //         userDetails.address[0];
  //       console.log(addressData, "userDetails");

  //       setAddressDetails((prev) => ({
  //         ...prev,
  //         id: addressData.id,
  //         area_id: addressData.area_id,
  //         block: addressData.block,
  //         street: addressData.street,
  //         avenue: addressData.avenue,
  //         house: addressData.house,
  //         floor: addressData.floor_number ?? addressData.floor,
  //         flat: addressData.flat_number ?? addressData.flat,
  //         lat: addressData.latitude ?? addressData.lat,
  //         lng: addressData.longitude ?? addressData.lng,
  //         fixedLat: addressData.latitude ?? addressData.lat,
  //         fixedLng: addressData.longitude ?? addressData.lng,
  //         addressType: addressData.addressType,
  //         addressName: addressData.addressName,
  //         special_directions: addressData?.special_directions,
  //       }));
  //       const addedAddress = [];
  //       areaDetails.data.governarate.forEach((address) => {
  //         const foundAddress = address.area.find(
  //           (area) => area.area_id == addressData.area_id
  //         );
  //         if (foundAddress) {
  //           addedAddress.push(foundAddress);
  //         }
  //       });

  //       const changeAreaResponse = await changeArea({
  //         vendors_id: details?.vendor?.vendors_id,
  //         area_id: addressData.area_id,
  //         vendorSlug: vendorSlug,
  //         user_string: localStorage.getItem("userID"),
  //       });
  //       if (changeAreaResponse.status === true) {
  //         if (changeAreaResponse.data.show_popup === 0) {
  //           const timeResponse = await getScheduleTime({
  //             vendors_id: details?.vendor?.vendors_id,
  //             area_id: addressData.area_id,
  //             vendorSlug: vendorSlug,
  //           });
  //           if (timeResponse.status) {
  //             let selectedBranch = timeResponse.data.branch;
  //             let activeBranch = areaDetails?.data?.branch?.filter(
  //               (branch) => branch?.id == selectedBranch?.id
  //             )[0];
  //             let estimationTime =
  //               timeResponse.data?.delivery_details?.delivery_expected_type != 6
  //                 ? timeResponse.data?.delivery_details?.delivery_expected_time
  //                 : 0;
  //             if (
  //               timeResponse.data.time == 1 &&
  //               addedAddress[0].availability_status == 1
  //             ) {
  //               setAreaDetails((k) => ({
  //                 ...k,
  //                 area: addedAddress[0].area_name,
  //                 minimum: addedAddress[0].minimum_charge,
  //                 shopOpen: timeResponse.data.time,
  //                 now: timeResponse.data.time,
  //                 branch: "",
  //                 ar_branch: "",
  //                 ar_area: addedAddress[0].area_name_ar,
  //                 area_id: addedAddress[0].area_id,
  //                 deliveryTiming: timeResponse.data.schedule_time,
  //                 ar_deliveryTiming: timeResponse.data.schedule_time_ar,
  //                 customDelivery:
  //                   timeResponse.data?.delivery_details
  //                     ?.delivery_expected_type == 6,

  //                 getDeliveryTiming: moment()
  //                   .add(estimationTime, "minutes")
  //                   .toDate(),
  //                 laterDeliveryTiming: moment()
  //                   .add(estimationTime, "minutes")
  //                   .toDate(),
  //                 branchForArea: {
  //                   ...timeResponse.data.branch,
  //                   end:
  //                     activeBranch?.office_end_time >
  //                     activeBranch?.office_start_time
  //                       ? moment(activeBranch?.office_end_time, "HH:mm:ss")
  //                       : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
  //                           1,
  //                           "days"
  //                         ),
  //                   start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
  //                 },
  //               }));
  //             } else {
  //               // console.log("In else condition");
  //               setAreaDetails((l) => ({
  //                 ...l,
  //                 area: addedAddress[0].area_name,
  //                 minimum: addedAddress[0].minimum_charge,
  //                 shopOpen:
  //                   addedAddress[0].availability_status == 1
  //                     ? timeResponse.data.time
  //                     : 2,
  //                 now:
  //                   addedAddress[0].availability_status == 1
  //                     ? timeResponse.data.time
  //                     : 2,
  //                 ar_area: addedAddress[0].area_name_ar,
  //                 area_id: addedAddress[0].area_id,
  //                 branch: "",
  //                 ar_branch: "",
  //                 deliveryTiming: timeResponse?.data?.schedule_time,
  //                 ar_deliveryTiming: timeResponse?.data?.schedule_time_ar,
  //                 customDelivery:
  //                   timeResponse.data?.delivery_details
  //                     ?.delivery_expected_type == 6,
  //                 getDeliveryTiming:
  //                   addedAddress[0].availability_status == 1 ||
  //                   timeResponse.data.time == 2
  //                     ? moment(
  //                         timeResponse.data.preorder_on,
  //                         "YYYY-MM-DD HH:mm:ss"
  //                       ).toDate()
  //                     : moment().add(estimationTime, "minutes").toDate(),
  //                 laterDeliveryTiming:
  //                   addedAddress[0].availability_status == 1 ||
  //                   timeResponse.data.time == 2
  //                     ? moment(
  //                         timeResponse.data.preorder_on,
  //                         "YYYY-MM-DD HH:mm:ss"
  //                       ).toDate()
  //                     : moment().add(estimationTime, "minutes").toDate(),
  //                 branchForArea: {
  //                   ...timeResponse.data.branch,
  //                   end:
  //                     activeBranch?.office_end_time >
  //                     activeBranch?.office_start_time
  //                       ? moment(activeBranch?.office_end_time, "HH:mm:ss")
  //                       : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
  //                           1,
  //                           "days"
  //                         ),
  //                   start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
  //                 },
  //               }));
  //             }
  //           } else {
  //           }
  //         } else {
  //         }
  //       }
  //     }
  //   })();
  // }, [userDetails]);

  return (
    <div
      style={{ position: "relative" }}
      className={`${priceChange && "stopScroll"}`}
    >
      {orderDetails && !noOrderID && (
        <>
          <div
            style={{
              padding:
                /* orderDetails?.payment_status === "1"
                  ?  */ "35px 30px 85px",
              /* : "35px 30px 20px" */ borderRadius: "32px",
              background: "#FFFFFF",
            }}
          >
            {orderDetails && (
              <CommonDeliveryStatus orderDetails={orderDetails} />
            )}
            {orderDetails ? (
              <DeliveryMapStatus
                location_coordinates={orderDetails?.location_coordinates}
                customer_details={orderDetails?.customer_details}
                payment_status={orderDetails?.payment_status}
              />
            ) : null}
            {orderDetails && (
              <CustomAccrodian accordianArray={accordianArray} />
            )}
          </div>

          <div className="order-status-reorder-button-div">
            <div
              className={`pay-now-button ${
                details?.vendor?.home_page_type === "18" &&
                "fashion-theme-pay-button"
              }`}
              onClick={async () => {
                if (orderDetails?.payment_status === "1") {
                  // handleReorder("0")
                  history.push("/");
                } else if (orderDetails?.has_register_item && cart) {
                  history.push("/checkout");
                } else {
                  history.push("/");
                  // handletryAgain();
                }
              }}
            >
              {orderDetails?.payment_status === "1"
                ? language === "ltr"
                  ? "Browse More"
                  : "تسوّق"
                : orderDetails?.has_register_item && cart
                ? language == "ltr"
                  ? "Checkout"
                  : "متابعة الطلب"
                : language === "ltr"
                ? "Try again"
                : "حاول مرة أُخرى"}
            </div>
          </div>

          {/* {orderDetails?.payment_status === "1" ? (
            <div className="order-status-reorder-button-div">
              <div
                className={`pay-now-button ${
                  details?.vendor?.home_page_type === "18" &&
                  "fashion-theme-pay-button"
                }`}
                onClick={() => {
                  if (orderDetails?.payment_status === "1") {
                    // handleReorder("0")
                    history.push("/");
                  }
                }}
              >
                {language === "ltr" ? "Browse More" : "تسوّق"}
              </div>
            </div>
          ) : (
            <div className="orderStatusPaymentDIv">
              <NewPaymentSelector
                setPayment={setPayment}
                payment={payment}
                setWidth={setWidth}
                width={width}
              />
              <button
                className={`pay-now-button ${
                  details?.vendor?.home_page_type === "18" &&
                  "fashion-theme-pay-button"
                }`}
              >
                {language === "ltr" ? "Pay" : "ادفع"}
              </button>
            </div>
          )} */}
        </>
      )}
      {loading && (
        <div
          style={{
            // background: "white",
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "60000",
          }}
          className="order-spinner-background"
        >
          <Spinner
            height="50px"
            color={details?.vendor?.vendor_color}
            size="6px"
          />
        </div>
      )}
      {noOrderID && (
        <div className="order-details-error">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={
                  language === "ltr"
                    ? details?.vendor?.english_new_background
                    : details?.vendor?.arabic_new_background
                }
                className="order-details-vendorImage"
              />
            </div>
            <div className="order-details-vendorName">
              {language === "ltr"
                ? `Unable to find the order, Please connect with ${details.vendor?.name}`
                : "تعذر العثور على الطلب ، الرجاء الاتصال بـ  ${details.vendor.name_ar}"}
            </div>
          </div>
        </div>
      )}

      <LongButtonsModal isOpen={isOpen} handleClose={() => setIsOpen(false)} />
    </div>
  );
};

export default NewOrderStatuPage;
