import React, { useEffect } from "react";
import NewOrderMode from ".";
import "./areaModal.css";
import LayoutHolder from "../LayoutHolder";

const AreaCommonModal = ({ openArea }) => {
  useEffect(() => {
    const modalContainer = document.getElementById("area-modal-container");
    const body = document.body;
    if (modalContainer && body) {
      if (openArea && modalContainer) {
        modalContainer.removeAttribute("class");
        modalContainer.classList.add("open");
        body.classList.add("modal-active");
      } else {
        modalContainer.classList.add("out");
        body.classList.remove("modal-active");
      }
    }
  }, [openArea]);
  return (
    <div
      id="area-modal-container"
      style={{ top: "0", height: "100vh", overflow: "hidden" }}
    >
      <div className={"modal-background"} onClick={(e) => e.stopPropagation()}>
        <div
          className={"modal"}
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div
                className={`col-lg-4 col-md-12 order-md-2 order-sm-2 order-lg-1 order-2 small-col p-0 details-container bg-white`}
                style={{ padding: "20px", borderRadius: "20px" }}
              >
                <NewOrderMode />
              </div>
              <div
                className={`col-lg-8 col-md-12 order-md-1 order-lg-2 order-sm-1 order-1 large-col p-0 none-res `}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaCommonModal;
