import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Link, useHistory } from "react-router-dom";
import {
  AreaContext,
  CartContext,
  ContactContext,
  InterNationalContext,
  LanguageContext,
  UserContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import { tele } from "../../services/constants";
import { getVendorCountries } from "../APIS/submitOrderApi";
import {
  getOrCreateUserDetails,
  GetUserDetails,
  saveUserAddress,
} from "../APIS/userApi";
import InternationalAddress from "../InterNationalDelivery/InternationalAddress";
import CommonHeader from "../NewCheckOutPage/Components/CommonHeader";
import DeliveryMapContainer from "./Component/DeliveryMapContainer";
import NewAddressForm from "./Component/NewAddressForm";
import "./newDeliveryStyles.css";
import BackComponent from "../NewCheckOutPage/Components/BackComponent";
import { useJsApiLoader } from "@react-google-maps/api";
import toast, { Toaster } from "react-hot-toast";
import { mapArea } from "../../commonFunction/areaConstant";
import Spinner from "../../SVGs/Spinner";

const NewDeliveryAddress = () => {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { areaDetails, setAreaDetails, setOpenArea } = useContext(AreaContext);
  const { userDetails } = useContext(UserContext);
  const [countryDropDown, setCountryDropDown] = useState();
  const [countryArray, setCountryArray] = useState();
  const { cart } = useContext(CartContext);
  const { addressDetails, setAddressDetails, contactDetails } =
    useContext(ContactContext);
  const { resetUserDetails, setUserDetails } = useContext(UserContext);
  const history = useHistory();
  const [showMap, setShowMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState({
    blockError: false,
    blockErrorMessage: "",
    blockErrorMessagear: "",
    streetError: false,
    streetErrorMessage: "",
    streetErrorMessagear: "",
    houseError: false,
    houseErrorMessage: "",
    houseErrorMessagear: "",
    addressNameError: false,
    addressNameErrorMessage: "",
    addressNameErrorMessagear: "",
    areaNameError: false,
    areaNameErrorMessage: "",
    areaNameErrorMessagear: "",
  });

  const [markerPosition, setMarkerPosition] = useState(null);
  const [selectedBounds, setSelectedBounds] = useState({
    north: 30.0978,
    south: 28.5244,
    east: 48.4161,
    west: 46.5682,
  });
  const { internationalDelivery, setInternationalDelivery } =
    useContext(InterNationalContext);
  const vendorSlug = useContext(VendorSlugContext);
  const [internationalError, setInternationalError] = useState({
    delivery_state: false,
    delivery_city: false,
    delivery_address1: false,
    delivery_address2: false,
  });

  useEffect(() => {
    if (!localStorage.getItem("contactInfo")) {
      history.push(`/`);
    }
    // if (!cart?.cartCount) {
    //   history.push(`/`);
    // } else if (
    //   !areaDetails?.area &&
    //   !areaDetails?.branch &&
    //   details?.vendor?.home_page_type != "18" &&
    //   (details?.vendor?.international_delivery === "3" ||
    //     details?.vendor?.international_delivery === "") &&
    //   internationalDelivery.delivery_country_code.toLowerCase() === "kw"
    // ) {
    //   history.push(`/area`);
    // }
  }, []);

  const blockValidation = (value) => {
    if (value == "") {
      setErrorState((errorState) => ({
        ...errorState,
        blockErrorMessage: "This field is compulsory",
        blockError: true,
        blockErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        blockErrorMessage: "",
        blockError: false,
        blockErrorMessagear: "",
      }));
      return false;
    }
  };

  const streetValidation = (value) => {
    if (value == "") {
      setErrorState((errorState) => ({
        ...errorState,
        streetErrorMessage: "This field is compulsory",
        streetError: true,
        streetErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        streetErrorMessage: "",
        streetError: false,
        streetErrorMessagear: "",
      }));
      return false;
    }
  };

  const houseValidation = (value) => {
    if (value == "") {
      setErrorState((errorState) => ({
        ...errorState,
        houseErrorMessage: "This field is compulsory",
        houseError: true,
        houseErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        houseErrorMessage: "",
        houseError: false,
        houseErrorMessagear: "",
      }));
      return false;
    }
  };

  const addressNameValidation = (value) => {
    if (value == "" || !value || undefined) {
      setErrorState((errorState) => ({
        ...errorState,
        addressNameErrorMessage: "This field is compulsory",
        addressNameError: true,
        addressNameErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        addressNameErrorMessage: "",
        addressNameError: false,
        addressNameErrorMessagear: "",
      }));
      return false;
    }
  };
  const areaNameValidation = (value) => {
    if (value == "" || !value || undefined) {
      setErrorState((errorState) => ({
        ...errorState,
        areaNameErrorMessage: "This field is compulsory",
        areaNameError: true,
        areaNameErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        areaNameErrorMessage: "",
        areaNameError: false,
        areaNameErrorMessagear: "",
      }));
      return false;
    }
  };

  const handleNext = async () => {
    if (
      details?.vendor?.international_delivery === "3" ||
      details?.vendor?.international_delivery === "" ||
      internationalDelivery.delivery_country_code.toUpperCase() === "KW"
    ) {
      if (showMap && markerPosition?.lat) {
        if (!areaDetails?.area && !areaDetails?.branch) {
          setOpenArea((prev) => ({ open: true, goHome: false }));
          // history.push(`/area`);
        } else {
          let block = details?.vendor?.enable_address_types?.includes(
            addressDetails.addressType
          )
            ? false
            : blockValidation(addressDetails.block);
          let street = details?.vendor?.enable_address_types?.includes(
            addressDetails.addressType
          )
            ? false
            : streetValidation(addressDetails.street);
          let addressName = addressNameValidation(addressDetails.addressName);
          let areaName = areaNameValidation(areaDetails.area);
          let house = houseValidation(addressDetails.house);
          if (
            !block &&
            !street &&
            !house &&
            !(addressName && !userDetails?.is_guest) &&
            !areaName
          ) {
            if (userDetails?.is_guest) {
              localStorage.removeItem("newPath");
              history.push("/checkout");
            } else {
              setLoading(true);
              const addResponse = await saveUserAddress({
                vendor_id: details.vendor.vendor_id,
                ecom_user_id: userDetails.id,
                address_type: addressDetails.addressType,
                area: areaDetails?.area,
                area_id: areaDetails?.area_id,
                country: tele[contactDetails?.phoneCode],
                country_id: `+${tele[contactDetails?.phoneCode]}`,
                block: addressDetails.block,
                street: addressDetails.street,
                avenue: addressDetails.avenue,
                house_number: addressDetails.house,
                floor_number: addressDetails.floor,
                flat_number: addressDetails.flat,
                user_lat: addressDetails?.lat || 0,
                user_long: addressDetails?.lng || 0,
                is_primary:
                  userDetails?.address?.length === 0
                    ? true
                    : addressDetails?.is_primary,
                special_directions: addressDetails?.special_directions,
                title: addressDetails.addressName,
                address_id: addressDetails.id ? addressDetails.id : null,
                jwt_token: localStorage.getItem("token"),
                user_id: localStorage.getItem("id"),
                language: language,
              });
              if (addResponse?.status) {
                const response = await GetUserDetails({
                  vendor_id: details?.vendor.vendors_id,
                  sendSMS: false,
                  country_code: `+${tele[contactDetails?.phoneCode]}`,
                  phone_number: contactDetails.phone,
                  jwt_token: localStorage.getItem("token"),
                  user_id: localStorage.getItem("id"),
                  language: language,
                });
                if (response?.status) {
                  setUserDetails({ ...response?.data });
                  setAddressDetails((k) => ({
                    ...k,
                    addressName: addressDetails.addressName,
                    id: addResponse.data,
                    addressType: addressDetails.addressType,
                    street: addressDetails.street,
                    block: addressDetails.block,
                    avenue: addressDetails.avenue,
                    house: addressDetails.house,
                    floor: addressDetails.floor,
                    flat: addressDetails.flat,
                    special_directions: addressDetails.special_directions,
                    lat: addressDetails.lat,
                    lng: addressDetails.lng,
                    is_primary: addressDetails.is_primary,
                  }));
                  setLoading(false);
                  if (localStorage.getItem("newPath") == "review") {
                    localStorage.removeItem("newPath");
                    history.push("/checkout");
                  } else {
                    history.push("/");
                  }
                } else {
                  toast.error(response?.message);
                  localStorage.removeItem("token");
                  localStorage.removeItem("contactInfo");
                  resetUserDetails();
                  history.push("/");
                }
              } else {
                toast.error(addResponse?.message);
              }
            }
          }
        }
        setShowMap(false);
      } else {
        let block = details?.vendor?.enable_address_types?.includes(
          addressDetails.addressType
        )
          ? false
          : blockValidation(addressDetails.block);
        let street = details?.vendor?.enable_address_types?.includes(
          addressDetails.addressType
        )
          ? false
          : streetValidation(addressDetails.street);
        let addressName = addressNameValidation(addressDetails.addressName);
        let areaName = areaNameValidation(areaDetails.area);
        let house = houseValidation(addressDetails.house);
        if (
          !block &&
          !street &&
          !house &&
          !(addressName && !userDetails?.is_guest)
        ) {
          setShowMap(true);
        }
      }
    } else {
      if (
        internationalDelivery.delivery_address1 !== "" &&
        internationalDelivery.delivery_address2 !== ""
      ) {
        localStorage.removeItem("newPath");
        history.push("/checkout");
      } else {
        let errorData = {};
        if (internationalDelivery.delivery_address1 === "") {
          errorData = { ...errorData, delivery_address1: true };
        } else {
          errorData = { ...errorData, delivery_address1: false };
        }
        if (internationalDelivery.delivery_address2 === "") {
          errorData = { ...errorData, delivery_address2: true };
        } else {
          errorData = { ...errorData, delivery_address2: false };
        }
        setInternationalError(errorData);
      }
    }
  };

  const convertArabicIndicToRegularArabic = (arabicIndicNumber) => {
    const arabicIndicDigits = [
      "٠",
      "١",
      "٢",
      "٣",
      "٤",
      "٥",
      "٦",
      "٧",
      "٨",
      "٩",
    ];
    let regularArabicNumber = "";

    for (let i = 0; i < arabicIndicNumber.length; i++) {
      const char = arabicIndicNumber.charAt(i);
      const digitIndex = arabicIndicDigits.indexOf(char);

      if (digitIndex !== -1) {
        regularArabicNumber += digitIndex;
      } else {
        regularArabicNumber += char;
      }
    }

    return regularArabicNumber;
  };

  const handleMapChanges = async (event, is_input) => {
    if (is_input) {
      let block = addressDetails.block;
      let street = addressDetails.street;
      if (event.target.name == "street") {
        street = event.target.value;
      } else {
        block = event.target.value;
      }
      // it is input from field
      if (areaDetails?.area && (block || street)) {
        // const encodedPlaceName = encodeURIComponent('kuwait ' + areaDetails?.area + ', block ' + block + ', street ' + street);
        let addressString = "";
        // if (areaDetails?.area.includes("Al")) {
        //     const newString = areaDetails?.area.split(" ").filter((ele, i) => i !== 0).join(' ')
        //     addressString = `${newString}, Kuwait`
        // }
        // else {
        addressString = `${areaDetails?.area}, Kuwait`;
        // }
        if (block) {
          addressString = `block ${convertArabicIndicToRegularArabic(
            block
          )}, ${addressString}`;
        } else {
          addressString = `block 1, ${addressString}`;
        }
        if (street) {
          addressString = `${convertArabicIndicToRegularArabic(
            street
          )} street, ${addressString}`;
        }

        // const encodedPlaceName = encodeURIComponent('street ' + street + ', block ' + block + ', ' + areaDetails?.area + ', Kuwait');
        const encodedPlaceName = encodeURIComponent(addressString);
        const respones = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedPlaceName}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        );
        if (respones.status === 200) {
          let lat = respones?.data?.results[0]?.geometry?.location?.lat;
          let lng = respones?.data?.results[0]?.geometry?.location?.lng;
          if (lat && lng) {
            setMarkerPosition({ lat, lng });
            setAddressDetails((a) => ({
              ...a,
              lat: lat,
              lng: lng,
            }));
          }
        }
      }
    }
  };
  const handleMapLoad = () => {
    const forClick = document.getElementById("forClickOnly");
    forClick.click();
  };

  useEffect(() => {
    if (
      internationalDelivery.delivery_country_code &&
      details?.vendor?.international_delivery !== "3" &&
      details?.vendor?.international_delivery !== ""
    ) {
      (async () => {
        const response = await getVendorCountries({
          vendor_id: details.vendor.vendors_id,
          ecom_vendor_id: details.vendor.ecommerce_vendor_id,
          vendor_slug: vendorSlug,
        });
        if (response.status) {
          let countryArray = [];
          response.data.map((ele) => {
            countryArray.push(ele.abbr);
          });
          const currentCountry = response.data.filter(
            (ele) => ele.abbr === internationalDelivery.delivery_country_code
          );
          if (currentCountry && currentCountry.length > 0) {
            setInternationalDelivery({
              ...internationalDelivery,
              delivery_country_code: currentCountry[0].abbr,
              country_id: currentCountry[0].country_id,
              delivery_expected_time: currentCountry[0]?.delivery_expected_time,
              delivery_expected_type: currentCountry[0]?.delivery_expected_type,
            });
          }
          setCountryArray(countryArray);
          setCountryDropDown(response.data);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (areaDetails?.area) {
      (async () => {
        if (areaDetails?.area == "Mutlaa") {
          setSelectedBounds({
            north: 29.5761,
            south: 29.38842,
            east: 47.66437,
            west: 47.538132,
          });
        } else {
          const selectedAra = mapArea.find(
            (ele) =>
              ele.area_name == areaDetails?.area ||
              ele.area_name_ar == areaDetails?.ar_area
          );
          const encodedPlaceName = encodeURIComponent(
            selectedAra.area_map + " Kuwait"
          );
          const respones = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedPlaceName}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
          );

          if (respones.status === 200) {
            setSelectedBounds({
              north:
                respones?.data?.results[0]?.geometry?.viewport?.northeast.lat,
              south:
                respones?.data?.results[0]?.geometry?.viewport?.southwest.lat,
              east: respones?.data?.results[0]?.geometry?.viewport?.northeast
                .lng,
              west: respones?.data?.results[0]?.geometry?.viewport?.southwest
                .lng,
            });
          }
        }
      })();
    }
  }, [areaDetails?.area]);

  return (
    <>
      <div
        className="holder-container"
        style={{ position: "relative", minHeight: "calc(100vh - 53px)" }}
      >
        <div id="forClickOnly"></div>
        {!showMap && (
          <div
            style={{ marginBottom: "20px" }}
            onClick={(e) => {
              e.preventDefault();
              // if (showMap) {
              //   setShowMap(false);
              // } else {
              //   history.goBack();
              // }
              history.push("/");
            }}
          >
            <BackComponent />
          </div>
        )}
        {/* <CommonHeader
          englishHeader="Delivery Address"
          arabicHeader="عنوان التسليم"
          fontWeight={600}
        /> */}
        {/* <div className="delivery-details-text">
          {language === "ltr" ? "Delivery Details" : "تفاصيل التوصيل"}
        </div> */}
        {(details?.vendor?.international_delivery !== "3" &&
          details?.vendor?.international_delivery !== "") ||
        internationalDelivery.delivery_country_code.toUpperCase() !== "KW" ? (
          <div style={{ paddingBottom: "10px" }}>
            <label className="country-select-label">
              {language === "ltr" ? "Country" : "دولة"}
            </label>
            <div className="international-country-selector">
              <ReactFlagsSelect
                selected={internationalDelivery.delivery_country_code}
                showSelectedLabel={true}
                searchable
                countries={countryArray}
                onSelect={(code) => {
                  const filterCountry = countryDropDown.filter(
                    (country) => country.abbr === code
                  );
                  setInternationalDelivery({
                    ...internationalDelivery,
                    delivery_country_code: code,
                    country_id: filterCountry[0].country_id,
                    delivery_country: filterCountry[0].country_name,
                    delivery_expected_time:
                      filterCountry[0]?.delivery_expected_time,
                    delivery_expected_type:
                      filterCountry[0]?.delivery_expected_type,
                  });
                  if (code !== "KW") {
                    setAreaDetails((areaDetails) => ({
                      ...areaDetails,
                      area: "",
                      branch: "",
                      branch_id: "",
                      area_id: "",
                      branchForArea: {},
                      deliveryTiming: "",
                      pickupTiming: "",
                      ar_area: "",
                      ar_branch: "",
                      ar_deliveryTiming: "",
                      ar_pickupTiming: "",
                    }));
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {details?.vendor?.international_delivery === "3" ||
        details?.vendor?.international_delivery === "" ||
        internationalDelivery.delivery_country_code.toUpperCase() === "KW" ||
        areaDetails.area_id !== "" ? (
          <>
            {showMap ? (
              <DeliveryMapContainer
                selectedArea={areaDetails?.area}
                // handleMapChanges={handleMapChanges}
                markerPosition={markerPosition}
                setMarkerPosition={setMarkerPosition}
                selectedBounds={selectedBounds}
                setSelectedBounds={setSelectedBounds}
                triggerClick={handleMapLoad}
              />
            ) : (
              <NewAddressForm
                areaDetails={areaDetails}
                blockValidation={blockValidation}
                streetValidation={streetValidation}
                houseValidation={houseValidation}
                // handleMapChanges={handleMapChanges}
                addressNameValidation={addressNameValidation}
                errorState={errorState}
                setMarkerPosition={setMarkerPosition}
              />
            )}
          </>
        ) : (
          <InternationalAddress internationalError={internationalError} />
        )}
      </div>
      <div className="toasterDiv">
        <Toaster position="top-left" reverseOrder={false} />
      </div>

      {loading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "8",
          }}
          className="order-spinner-background"
        >
          <Spinner
            height="50px"
            color={details?.vendor?.vendor_color}
            size="6px"
          />
        </div>
      )}

      <div
        className={`contact-details-bottom-button ${
          details?.vendor?.home_page_type === "18" && "fashion-theme"
        }`}
      >
        {showMap && (
          <Link
            className="contact-details-back-button"
            onClick={(e) => {
              e.preventDefault();
              if (showMap) {
                setShowMap(false);
              } else {
                history.goBack();
              }
            }}
          >
            {language === "ltr" ? "Back" : "خلف"}
          </Link>
        )}
        <Link
          className="contact-details-next-button"
          style={showMap && !markerPosition?.lat ? { background: "grey" } : {}}
          onClick={handleNext}
        >
          {language === "ltr" ? "Next" : "متابعة"}
        </Link>
      </div>
    </>
  );
};

export default NewDeliveryAddress;
