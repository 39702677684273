import React, { useContext, useEffect, useState } from "react";
import CommonBackButton from "../NewSideBar/Components/CommonBackButton";
import {
  AreaContext,
  ContactContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { ReactPixel } from "react-snapchat-pixel";
import { changeArea, getScheduleTime } from "../APIS/areaAndTimesAPi";
import $ from "jquery";
import SearchIcon from "../../SVGs/SearchIcon";
import SearchNone from "../HomePage/components/SearchNone";
import ModalComponent from "../ModalComponent";
import Spinner from "../../SVGs/Spinner";
import { mapArea } from "../../commonFunction/areaConstant";
import axios from "axios";

const AreaModal = ({ handleClose, showAreaModal, setMarkerPosition }) => {
  const { language } = useContext(LanguageContext);
  const history = useHistory();
  const vendorSlug = useContext(VendorSlugContext);
  const { setAddressDetails, addressDetails } = useContext(ContactContext);
  const [loading, setLoading] = useState(false);
  const [areaSearch, setAreaSearch] = useState("");
  const [governarateActive, setGovernarateActive] = useState(null);
  const [area, setArea] = useState([]);
  const details = useContext(VendorContext);
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const [active, setActive] = useState("");

  useEffect(() => {
    const modalContainer = document.getElementById("termsmodal-container");
    const body = document.body;
    if (modalContainer && body) {
      if (showAreaModal && modalContainer) {
        modalContainer.removeAttribute("class");
        modalContainer.classList.add("open");
        body.classList.add("modal-active");
      } else {
        modalContainer.classList.add("out");
        body.classList.remove("modal-active");
      }
    }
  }, [showAreaModal]);

  useEffect(() => {
    if (areaSearch == "") {
      if (areaDetails.data.governarate) {
        let temp = Object.values(areaDetails?.data?.governarate)?.map(
          (k, i) => {
            return false;
          }
        );
        setGovernarateActive(temp);
        setArea(areaDetails?.data?.governarate);
      }
    } else {
      if (areaDetails.data.governarate) {
        let gov_filter = areaDetails?.data?.governarate.map((k, i) => {
          let filtered = k.area.filter(
            (l, j) =>
              l?.area_name?.toUpperCase().indexOf(areaSearch?.toUpperCase()) >
                -1 ||
              l?.area_name_ar
                ?.toUpperCase()
                .indexOf(areaSearch?.toUpperCase()) > -1
          );
          if (filtered.length != 0) return { ...k, area: [...filtered] };
        });
        gov_filter = gov_filter.filter((k, i) => k);
        if (gov_filter.length != 0) {
          let temp = gov_filter?.map((k, i) => {
            return true;
          });
          setGovernarateActive(temp);
          setArea(gov_filter);
        } else setArea([]);
      }
    }
  }, [areaSearch, areaDetails?.data?.governarate]);

  const changeAreaApi = async (key) => {
    try {
      const response = await changeArea({
        vendors_id: details?.vendor?.vendors_id,
        area_id: key,
        vendorSlug: vendorSlug,
        user_string: localStorage.getItem("userID"),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getScheduleTimeApi = async (key) => {
    try {
      const response = await getScheduleTime({
        vendors_id: details?.vendor?.vendors_id,
        area_id: key,
        vendorSlug: vendorSlug,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const onAreaClick = async (eng, key, min, avail, arab) => {
    try {
      setTimeout(() => {
        setLoading(true);
      }, 500);
      setActive((a) => eng);
      // const response = await changeArea({
      //   vendors_id: details?.vendor?.vendors_id,
      //   area_id: key,
      //   vendorSlug: vendorSlug,
      // });
      // if (response.status === true) {
      //   const timeResponse = await getScheduleTime({
      //     vendors_id: details?.vendor?.vendors_id,
      //     area_id: key,
      //     vendorSlug: vendorSlug,
      //   });
      //   if (timeResponse.status) {
      //     let selectedBranch = timeResponse.data.branch;
      //     let activeBranch = areaDetails?.data?.branch?.filter(
      //       (branch) => branch?.id == selectedBranch?.id
      //     )[0];
      //     let estimationTime =
      //       timeResponse.data?.delivery_details?.delivery_expected_type != 6
      //         ? timeResponse.data?.delivery_details?.delivery_expected_time
      //         : 0;
      //     if (timeResponse.data.time == 1 && avail == 1) {
      //       setAddressDetails((prev) => ({
      //         ...prev,
      //         block: "",
      //         street: "",
      //       }));
      //       setAreaDetails((k) => ({
      //         ...areaDetails,
      //         area: eng,
      //         minimum: min,
      //         shopOpen: timeResponse.data.time,
      //         now: timeResponse.data.time,
      //         branch: "",
      //         ar_branch: "",
      //         ar_area: arab,
      //         area_id: key,
      //         deliveryTiming: timeResponse.data.schedule_time,
      //         ar_deliveryTiming: timeResponse.data.schedule_time_ar,
      //         customDelivery:
      //           timeResponse.data?.delivery_details?.delivery_expected_type == 6,

      //         getDeliveryTiming: moment().add(estimationTime, "minutes").toDate(),
      //         laterDeliveryTiming: moment()
      //           .add(estimationTime, "minutes")
      //           .toDate(),
      //         branchForArea: {
      //           ...timeResponse.data.branch,
      //           end:
      //             activeBranch?.office_end_time > activeBranch?.office_start_time
      //               ? moment(activeBranch?.office_end_time, "HH:mm:ss")
      //               : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
      //                 1,
      //                 "days"
      //               ),
      //           start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
      //         },
      //       }));
      //     } else {
      //       setAreaDetails((l) => ({
      //         ...areaDetails,
      //         area: eng,
      //         minimum: min,
      //         shopOpen: avail == 1 ? timeResponse.data.time : 2,
      //         now: avail == 1 ? timeResponse.data.time : 2,
      //         ar_area: arab,
      //         area_id: key,
      //         branch: "",
      //         ar_branch: "",
      //         deliveryTiming: timeResponse?.data?.schedule_time,
      //         ar_deliveryTiming: timeResponse?.data?.schedule_time_ar,
      //         customDelivery:
      //           timeResponse.data?.delivery_details?.delivery_expected_type == 6,
      //         getDeliveryTiming:
      //           avail == 1 || timeResponse.data.time == 2
      //             ? moment(
      //               timeResponse.data.preorder_on,
      //               "YYYY-MM-DD HH:mm:ss"
      //             ).toDate()
      //             : moment().add(estimationTime, "minutes").toDate(),
      //         laterDeliveryTiming:
      //           avail == 1 || timeResponse.data.time == 2
      //             ? moment(
      //               timeResponse.data.preorder_on,
      //               "YYYY-MM-DD HH:mm:ss"
      //             ).toDate()
      //             : moment().add(estimationTime, "minutes").toDate(),
      //         branchForArea: {
      //           ...timeResponse.data.branch,
      //           end:
      //             activeBranch?.office_end_time > activeBranch?.office_start_time
      //               ? moment(activeBranch?.office_end_time, "HH:mm:ss")
      //               : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
      //                 1,
      //                 "days"
      //               ),
      //           start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
      //         },
      //       }));
      //     }
      //     handleClose(false, "");
      //     setLoading(false);
      //   } else {
      //     setLoading(false);
      //   }
      //   setMarkerPosition({ lat: "", lng: "" })
      // } else {
      //   console.log(response);
      // }

      const [areaResponse, timeResponse] = await Promise.all([
        changeAreaApi(key),
        getScheduleTimeApi(key),
      ]);
      if (areaResponse.status === true && areaResponse.data.show_popup === 0) {
        if (timeResponse.status) {
          let selectedBranch = timeResponse.data.branch;
          let activeBranch = areaDetails?.data?.branch?.filter(
            (branch) => branch?.id == selectedBranch?.id
          )[0];
          let estimationTime =
            timeResponse.data?.delivery_details?.delivery_expected_type != 6
              ? timeResponse.data?.delivery_details?.delivery_expected_time
              : 0;
          if (timeResponse.data.time == 1 && avail == 1) {
            setAddressDetails((prev) => ({
              ...prev,
              block: "",
              street: "",
            }));
            setAreaDetails((k) => ({
              ...areaDetails,
              area: eng,
              minimum: min,
              shopOpen: timeResponse.data.time,
              now: timeResponse.data.time,
              branch: "",
              ar_branch: "",
              ar_area: arab,
              area_id: key,
              deliveryTiming: timeResponse.data.schedule_time,
              ar_deliveryTiming: timeResponse.data.schedule_time_ar,
              customDelivery:
                timeResponse.data?.delivery_details?.delivery_expected_type ==
                6,

              getDeliveryTiming: moment()
                .add(estimationTime, "minutes")
                .toDate(),
              laterDeliveryTiming: moment()
                .add(estimationTime, "minutes")
                .toDate(),
              branchForArea: {
                ...timeResponse.data.branch,
                end:
                  activeBranch?.office_end_time >
                  activeBranch?.office_start_time
                    ? moment(activeBranch?.office_end_time, "HH:mm:ss")
                    : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
                        1,
                        "days"
                      ),
                start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
              },
            }));
          } else {
            setAreaDetails((l) => ({
              ...areaDetails,
              area: eng,
              minimum: min,
              shopOpen: avail == 1 ? timeResponse.data.time : 2,
              now: avail == 1 ? timeResponse.data.time : 2,
              ar_area: arab,
              area_id: key,
              branch: "",
              ar_branch: "",
              deliveryTiming: timeResponse?.data?.schedule_time,
              ar_deliveryTiming: timeResponse?.data?.schedule_time_ar,
              customDelivery:
                timeResponse.data?.delivery_details?.delivery_expected_type ==
                6,
              getDeliveryTiming:
                avail == 1 || timeResponse.data.time == 2
                  ? moment(
                      timeResponse.data.preorder_on,
                      "YYYY-MM-DD HH:mm:ss"
                    ).toDate()
                  : moment().add(estimationTime, "minutes").toDate(),
              laterDeliveryTiming:
                avail == 1 || timeResponse.data.time == 2
                  ? moment(
                      timeResponse.data.preorder_on,
                      "YYYY-MM-DD HH:mm:ss"
                    ).toDate()
                  : moment().add(estimationTime, "minutes").toDate(),
              branchForArea: {
                ...timeResponse.data.branch,
                end:
                  activeBranch?.office_end_time >
                  activeBranch?.office_start_time
                    ? moment(activeBranch?.office_end_time, "HH:mm:ss")
                    : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
                        1,
                        "days"
                      ),
                start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
              },
            }));
          }
          handleClose(false, "");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setMarkerPosition({ lat: "", lng: "" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const areaSearchChange = (e) => {
    setAreaSearch(e.target.value);
  };

  const moveup = () => {
    $("html, body").animate(
      {
        scrollTop:
          $(`#deliverySearch`).offset().top - $(`#deliverySearch`).offset().top,
      },
      "slow"
    );
  };

  const handleCityClick = (city) => {
    if (city?.availability_status == 1) {
      onAreaClick(
        city?.area_name,
        city?.area_id,
        city?.minimum_charge,
        city?.availability_status,
        city?.area_name_ar
      );
    } else {
      setActive((a) => city?.area_name);
    }
  };

  return (
    <div
      id="termsmodal-container"
      style={{ top: "-53px", height: "100vh", overflow: "hidden" }}
    >
      <div className={"modal-background"} onClick={(e) => e.stopPropagation()}>
        <div
          className={"modal"}
          style={{ padding: "10px", width: "100%", position: "relative" }}
        >
          <div
            style={{
              overflowY: "auto",
              paddingRight: 10,
              height: "90vh",
              overflowX: "hidden",
              paddingLeft: 10,
              color: "#000",
              background: "#fff",
              borderRadius: "20px",
              width: "100%",
              position: "relative",
            }}
            className="paymentTypeMain firstDetailsTab scrollbarPaylinkCard"
          >
            <div
              className="headerModal"
              style={{
                position: "sticky",
                top: "0",
                width: "100%",
                background: "white",
              }}
            >
              <div
                style={{
                  margin: 0,
                  position: "absolute",
                }}
                className="goBackButton"
              >
                <div
                  onClick={() => {
                    handleClose(false, "");
                  }}
                  style={{
                    marginBottom: "0",
                    paddingTop: "15px",
                    marginLeft: language === "ltr" ? 0 : "-15px",
                    marginRight: language === "ltr" ? "-15px" : 0,
                  }}
                >
                  <CommonBackButton setBurger={handleClose} />
                </div>
              </div>
            </div>
            <div
              className="delivery-areas-mainDiv"
              style={{ marginTop: "40px", padding: "20px", width: "100%" }}
            >
              <div
                className={`delivery-area-search  ${
                  details?.vendor?.home_page_type === "18" &&
                  "fashion-theme-border"
                }`}
              >
                <SearchIcon color={details?.vendor?.vendor_color} />
                <input
                  onChange={(e) => areaSearchChange(e)}
                  onFocus={moveup}
                  type="search"
                  id="deliverySearch"
                  placeholder={
                    language === "ltr"
                      ? "Search in cities"
                      : "بحث بأسماء المناطق"
                  }
                  className="delivery-area-search-input"
                  value={areaSearch}
                ></input>
              </div>
              <div className=" accordion-container delivery-area-governarate">
                {area?.length != 0
                  ? area?.map((gov, k) => (
                      <React.Fragment key={k}>
                        <div
                          className="delivery-area-governarate-holder"
                          onClick={() =>
                            setGovernarateActive({
                              ...governarateActive,
                              [k]: !governarateActive[k],
                            })
                          }
                        >
                          <button
                            className={`delivery-area-governarate-header ${
                              governarateActive[k] ? "active" : ""
                            }`}
                          >
                            {language === "ltr"
                              ? gov?.governarate_name
                              : gov?.governarate_name_ar}
                          </button>
                          <div
                            className={`delivery-arrow ${
                              governarateActive[k] ? "rotate" : "unrotate"
                            }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M7.5 15L12.5 10L7.5 5"
                                stroke="black"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`delivery-area-collapse ${
                            governarateActive[k] ? "active" : ""
                          }`}
                        >
                          <ul>
                            {gov?.area
                              ?.sort((prevCity, nextCity) =>
                                prevCity?.area_name.localeCompare(
                                  nextCity?.area_name
                                )
                              )
                              .map((city, j) => (
                                <div
                                  className={`area-div-hover ${
                                    city?.area_name === active
                                      ? "area_border"
                                      : ""
                                  }`}
                                  key={j}
                                >
                                  <li
                                    key={j}
                                    onClick={() => {
                                      handleCityClick(city);
                                    }}
                                  >
                                    <p>
                                      {language === "ltr"
                                        ? city?.area_name
                                        : city?.area_name_ar}
                                    </p>
                                    {city?.availability_status != 1 && (
                                      <span className="branch-avai-status">
                                        {language === "ltr"
                                          ? "STORE IS BUSY"
                                          : "المتجر مشغول "}
                                      </span>
                                    )}
                                  </li>
                                </div>
                              ))}
                          </ul>
                        </div>
                      </React.Fragment>
                    ))
                  : areaSearch != "" && (
                      <SearchNone searchText={areaSearch}></SearchNone>
                    )}
              </div>

              {loading && (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "8",
                  }}
                  className="order-spinner-background"
                >
                  <Spinner
                    height="50px"
                    color={details?.vendor?.vendor_color}
                    size="6px"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaModal;
