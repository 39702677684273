import React, { useContext, useEffect, useState } from "react";
import CommonHeader from "../../NewCheckOutPage/Components/CommonHeader";
import {
  AreaContext,
  CartContext,
  ContactContext,
  LanguageContext,
  UserContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import "./addressSection.css";
import AddressCard from "./components/AddressCard";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CommonSectionHeader from "../../NewCheckOutPage/Components/CommonSectionHeader";
import { changeArea, getScheduleTime } from "../../APIS/areaAndTimesAPi";
import moment from "moment";
import Spinner from "../../../SVGs/Spinner";
import { deleteUserAddress, GetUserDetails } from "../../APIS/userApi";
import { tele, telecount } from "../../../services/constants";
import Arrow from "../../NewOrderStatusPage/Components/Arrow";
import EditIcon from "../../../SVGs/EditIcon";
import ReactFlagsSelect from "react-flags-select";
import UserIcon from "../../../SVGs/UserIcon";
import toast, { Toaster } from "react-hot-toast";
import BackComponent from "../../NewCheckOutPage/Components/BackComponent";
import Card from "../../Card/Card";
import ThreeDots from "../../../SVGs/ThreeDots";
import { getAddressType } from "../../../commonFunction/commonFunction";
import Divider from "../../Divider/Divider";
import Pointer from "../../../SVGs/Pointer";
import RightArrow from "../../../SVGs/RightArrow";

const AddressSection = ({ type }) => {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { addressDetails, setAddressDetails } = useContext(ContactContext);
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const { userDetails, setUserDetails, resetUserDetails } =
    useContext(UserContext);
  const history = useHistory();
  const [addressData, setAddressData] = useState([]);
  const vendorSlug = useContext(VendorSlugContext);
  const { cart } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const { contactDetails, setContactDetails } = useContext(ContactContext);
  const [isClicked, setIsClicked] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    // setAddressData(addressArray);
    getAddressData();
  }, [userDetails.address]);

  // useEffect(() => {
  //   //get user details and address and assign it
  //   if (
  //     details?.vendor &&
  //     localStorage.getItem("contactInfo") &&
  //     localStorage.getItem("token") &&
  //     areaDetails?.data?.governarate
  //   ) {
  //     (async () => {
  //       const contactInfo = JSON.parse(localStorage.getItem("contactInfo"));
  //       const response = await GetUserDetails({
  //         vendor_id: details?.vendor.vendors_id,
  //         sendSMS: false,
  //         country_code: tele[contactInfo.code],
  //         phone_number: contactInfo.phone,
  //         jwt_token: localStorage.getItem("token"),
  //       });
  //       setUserDetails({ ...response?.data });
  //       setContactDetails({
  //         ...contactDetails,
  //         phone: response?.data?.phone,
  //         phoneCode: response?.data?.code,
  //         name: response?.data?.name,
  //         email: response?.data?.email,
  //       });
  //     })();
  //   }
  // }, [details, areaDetails?.data?.governarate]);

  const getAddressData = () => {
    if (userDetails.address) {
      const addresslist = userDetails.address.map((ele) => {
        return {
          id: ele.id,
          addressName: ele.title ?? ele.addressName,
          addressType: ele.address_type,
          area: ele.area,
          area_id: ele.area_id,
          ar_area: ele.ar_area,
          street: ele.street,
          block: ele.block,
          avenue: ele.avenue,
          house: ele.house_number,
          flat: ele.flat_number,
          floor: ele.floor_number,
          special_directions: ele?.special_directions,
          lat: ele?.latitude,
          lng: ele?.longitude,
          is_primary: ele?.is_primary,
        };
      });
      setAddressData(addresslist);
    }
  };

  const addAddress = () => {
    setAddressDetails({
      block: "",
      street: "",
      avenue: "",
      house: "",
      floor: "",
      flat: "",
      special_directions: "",
      lat: 29.378,
      lng: 47.99,
      fixedLat: 29.378,
      fixedLng: 47.99,
      addressString: "",
      addressType: "1",
    });
    history.push(`/delivery-address`, {
      from: "saved-address",
    });
  };

  const updateUserResponse = async () => {
    const contactInfo = JSON.parse(localStorage.getItem("contactInfo"));
    const response = await GetUserDetails({
      vendor_id: details?.vendor.vendors_id,
      sendSMS: false,
      country_code: `+${tele[contactInfo.code]}`,
      phone_number: contactInfo.phone,
      jwt_token: localStorage.getItem("token"),
      user_id: localStorage.getItem("id"),
      language: language,
    });
    if (response?.status) {
      setUserDetails({ ...response?.data });
      if (response?.data?.address?.length == 0) {
        setAddressDetails({
          block: "",
          street: "",
          avenue: "",
          house: "",
          floor: "",
          flat: "",
          special_directions: "",
          lat: 29.378,
          lng: 47.99,
          fixedLat: 29.378,
          fixedLng: 47.99,
          addressString: "",
          addressType: "1",
        });
      }
    } else {
      toast.error(response?.message);
      localStorage.removeItem("token");
      localStorage.removeItem("contactInfo");
      resetUserDetails();
      history.push("/");
    }
  };

  const deleteAddress = async (id) => {
    setLoading(true);

    const response = await deleteUserAddress({
      ecom_user_id: userDetails.id,
      address_id: id,
      jwt_token: localStorage.getItem("token"),
      user_id: localStorage.getItem("id"),
      language: language,
    });

    if (response?.status) {
      setLoading(false);
      updateUserResponse();
    } else {
      setLoading(false);
      toast.error(response?.message);
    }
  };

  const updateAddress = (id) => {
    addressData.map(async (address) => {
      if (address.id == id) {
        setAddressDetails((k) => ({
          ...k,
          addressName: address.addressName ?? address.title,
          id: address.id,
          addressType: address.addressType,
          street: address.street,
          block: address.block,
          avenue: address.avenue,
          house: address.house,
          floor: address.floor,
          flat: address.flat,
          special_directions: address.special_directions,
          lat: address.lat,
          lng: address.lng,
          is_primary: address.is_primary,
        }));
        let findAreaDetails = {};
        areaDetails.data.governarate?.map((governarate) =>
          governarate.area.map((area) => {
            if (area.area_id == address.area_id) {
              findAreaDetails = area;
            }
          })
        );
        const response = await changeArea({
          vendors_id: details?.vendor?.vendors_id,
          area_id: address.area_id,
          vendorSlug: vendorSlug,
        });
        if (response.status === true) {
          if (response.data.show_popup === 0) {
            const timeResponse = await getScheduleTime({
              vendors_id: details?.vendor?.vendors_id,
              area_id: address.area_id,
              vendorSlug: vendorSlug,
            });
            if (timeResponse.status) {
              let selectedBranch = timeResponse.data.branch;
              let activeBranch = areaDetails?.data?.branch?.filter(
                (branch) => branch?.id == selectedBranch?.id
              )[0];
              let estimationTime =
                timeResponse.data?.delivery_details?.delivery_expected_type != 6
                  ? timeResponse.data?.delivery_details?.delivery_expected_time
                  : 0;
              if (
                timeResponse.data.time == 1 &&
                findAreaDetails.availability_status == 1
              ) {
                setAreaDetails((k) => ({
                  ...areaDetails,
                  area: address.area,
                  minimum: findAreaDetails.minimum_charge,
                  shopOpen: timeResponse.data.time,
                  now: timeResponse.data.time,
                  branch: "",
                  ar_branch: "",
                  ar_area: address.ar_area,
                  area_id: address.area_id,
                  deliveryTiming: timeResponse.data.schedule_time,
                  ar_deliveryTiming: timeResponse.data.schedule_time_ar,
                  customDelivery:
                    timeResponse.data?.delivery_details
                      ?.delivery_expected_type == 6,

                  getDeliveryTiming: moment()
                    .add(estimationTime, "minutes")
                    .toDate(),
                  laterDeliveryTiming: moment()
                    .add(estimationTime, "minutes")
                    .toDate(),
                  branchForArea: {
                    ...timeResponse.data.branch,
                    end:
                      activeBranch?.office_end_time >
                      activeBranch?.office_start_time
                        ? moment(activeBranch?.office_end_time, "HH:mm:ss")
                        : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
                            1,
                            "days"
                          ),
                    start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
                  },
                }));
              } else {
                setAreaDetails((l) => ({
                  ...areaDetails,
                  area: address.area,
                  minimum: findAreaDetails.minimum_charge,
                  shopOpen:
                    findAreaDetails.availability_status == 1
                      ? timeResponse.data.time
                      : 2,
                  now:
                    findAreaDetails.availability_status == 1
                      ? timeResponse.data.time
                      : 2,
                  ar_area: address.ar_area,
                  area_id: address.area_id,
                  branch: "",
                  ar_branch: "",
                  deliveryTiming: timeResponse?.data?.schedule_time,
                  ar_deliveryTiming: timeResponse?.data?.schedule_time_ar,
                  customDelivery:
                    timeResponse.data?.delivery_details
                      ?.delivery_expected_type == 6,
                  getDeliveryTiming:
                    findAreaDetails.availability_status == 1 ||
                    timeResponse.data.time == 2
                      ? moment(
                          timeResponse.data.preorder_on,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toDate()
                      : moment().add(estimationTime, "minutes").toDate(),
                  laterDeliveryTiming:
                    findAreaDetails.availability_status == 1 ||
                    timeResponse.data.time == 2
                      ? moment(
                          timeResponse.data.preorder_on,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toDate()
                      : moment().add(estimationTime, "minutes").toDate(),
                  branchForArea: {
                    ...timeResponse.data.branch,
                    end:
                      activeBranch?.office_end_time >
                      activeBranch?.office_start_time
                        ? moment(activeBranch?.office_end_time, "HH:mm:ss")
                        : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
                            1,
                            "days"
                          ),
                    start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
                  },
                }));
              }
            } else {
            }
          } else {
          }
        }
        history.push("/delivery-address");
      }
    });
  };

  const handleClick = (id) => {
    setIsClicked(id);
    setTimeout(() => {
      setIsClicked("");
    }, 200);
  };

  const houseLabel = (addressType) => {
    switch (addressType) {
      case "1":
        return language == "ltr" ? "House No." : "عمارة";
      case "2":
      case "3":
        return language == "ltr" ? "Building No." : "عمارة";
      case "4":
        return language == "ltr" ? "School Name" : "اسم المدرسة";
      case "5":
        return language == "ltr" ? "Mosque Name" : "اسم المسجد";
      case "6":
        return language == "ltr" ? "Government Entity" : "مبنى حكومة";
      default:
        return "";
    }
  };

  const flatLabel = (addressType) => {
    switch (addressType) {
      case "2":
        return language == "ltr" ? "Flat No." : "رقم الشقة";
      case "3":
        return language == "ltr" ? "Office No." : "رقم المكتب";

      default:
        return "";
    }
  };

  const handleAddressClick = async (addressData) => {
    setAddressDetails((prev) => ({
      ...prev,
      id: addressData.id,
      area_id: addressData.area_id,
      block: addressData.block,
      street: addressData.street,
      avenue: addressData.avenue,
      house: addressData.house,
      floor: addressData.floor_number ?? addressData.floor,
      flat: addressData.flat_number ?? addressData.flat,
      lat: addressData.latitude ?? addressData.lat,
      lng: addressData.longitude ?? addressData.lng,
      fixedLat: addressData.latitude ?? addressData.lat,
      fixedLng: addressData.longitude ?? addressData.lng,
      addressType: addressData.addressType,
      addressName: addressData.title,
      special_directions: addressData?.special_directions,
    }));
    const addedAddress = [];
    areaDetails.data.governarate.forEach((address) => {
      const foundAddress = address.area.find(
        (area) => area.area_id == addressData.area_id
      );
      if (foundAddress) {
        addedAddress.push(foundAddress);
      }
    });
    const changeAreaResponse = await changeArea({
      vendors_id: details?.vendor?.vendors_id,
      area_id: addressData.area_id,
      vendorSlug: vendorSlug,
      user_string: localStorage.getItem("userID"),
    });
    if (changeAreaResponse.status === true) {
      if (changeAreaResponse.data.show_popup === 0) {
        const timeResponse = await getScheduleTime({
          vendors_id: details?.vendor?.vendors_id,
          area_id: addressData.area_id,
          vendorSlug: vendorSlug,
        });
        if (timeResponse.status) {
          let selectedBranch = timeResponse.data.branch;
          let activeBranch = areaDetails?.data?.branch?.filter(
            (branch) => branch?.id == selectedBranch?.id
          )[0];
          let estimationTime =
            timeResponse.data?.delivery_details?.delivery_expected_type != 6
              ? timeResponse.data?.delivery_details?.delivery_expected_time
              : 0;
          if (
            timeResponse.data.time == 1 &&
            addedAddress[0].availability_status == 1
          ) {
            setAreaDetails((k) => ({
              ...k,
              area: addedAddress[0].area_name,
              minimum: addedAddress[0].minimum_charge,
              shopOpen: timeResponse.data.time,
              now: timeResponse.data.time,
              branch: "",
              ar_branch: "",
              ar_area: addedAddress[0].area_name_ar,
              area_id: addedAddress[0].area_id,
              deliveryTiming: timeResponse.data.schedule_time,
              ar_deliveryTiming: timeResponse.data.schedule_time_ar,
              customDelivery:
                timeResponse.data?.delivery_details?.delivery_expected_type ==
                6,

              getDeliveryTiming: moment()
                .add(estimationTime, "minutes")
                .toDate(),
              laterDeliveryTiming: moment()
                .add(estimationTime, "minutes")
                .toDate(),
              branchForArea: {
                ...timeResponse.data.branch,
                end:
                  activeBranch?.office_end_time >
                  activeBranch?.office_start_time
                    ? moment(activeBranch?.office_end_time, "HH:mm:ss")
                    : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
                        1,
                        "days"
                      ),
                start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
              },
            }));
          } else {
            // console.log("In else condition");
            setAreaDetails((l) => ({
              ...l,
              area: addedAddress[0].area_name,
              minimum: addedAddress[0].minimum_charge,
              shopOpen:
                addedAddress[0].availability_status == 1
                  ? timeResponse.data.time
                  : 2,
              now:
                addedAddress[0].availability_status == 1
                  ? timeResponse.data.time
                  : 2,
              ar_area: addedAddress[0].area_name_ar,
              area_id: addedAddress[0].area_id,
              branch: "",
              ar_branch: "",
              deliveryTiming: timeResponse?.data?.schedule_time,
              ar_deliveryTiming: timeResponse?.data?.schedule_time_ar,
              customDelivery:
                timeResponse.data?.delivery_details?.delivery_expected_type ==
                6,
              getDeliveryTiming:
                addedAddress[0].availability_status == 1 ||
                timeResponse.data.time == 2
                  ? moment(
                      timeResponse.data.preorder_on,
                      "YYYY-MM-DD HH:mm:ss"
                    ).toDate()
                  : moment().add(estimationTime, "minutes").toDate(),
              laterDeliveryTiming:
                addedAddress[0].availability_status == 1 ||
                timeResponse.data.time == 2
                  ? moment(
                      timeResponse.data.preorder_on,
                      "YYYY-MM-DD HH:mm:ss"
                    ).toDate()
                  : moment().add(estimationTime, "minutes").toDate(),
              branchForArea: {
                ...timeResponse.data.branch,
                end:
                  activeBranch?.office_end_time >
                  activeBranch?.office_start_time
                    ? moment(activeBranch?.office_end_time, "HH:mm:ss")
                    : moment(activeBranch?.office_end_time, "HH:mm:ss").add(
                        1,
                        "days"
                      ),
                start: moment(activeBranch?.office_start_time, "HH:mm:ss"),
              },
            }));
          }
        } else {
        }
      } else {
      }
    }
    history.push("/");
  };

  return (
    <div>
      <div className="toasterDiv">
        <Toaster position="top-left" reverseOrder={false} />
      </div>

      <div
        className="holder-container"
        style={{ position: "relative", minHeight: "calc(100vh - 53px)" }}
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          <BackComponent />
        </div>
        <>
          {/* <div style={{ fontSize: language == "ltr" ? "23px" : "25px", marginBottom: "10px" }}>
            {language == "ltr" ? "My Personal Info" : "معلوماتي الشخصية"}
          </div> */}

          <CommonHeader
            englishHeader={"My Personal Info"}
            arabicHeader={"معلوماتي الشخصية"}
            fontWeight={500}
          />

          <Card
            icon={<UserIcon />}
            info={{
              name: userDetails?.name,
              flag: (
                <ReactFlagsSelect
                  className="showFlag-only"
                  selected={contactDetails?.phoneCode}
                  showSelectedLabel={false}
                  disabled
                  customLabels={telecount}
                />
              ),
              phoneCode: contactDetails?.phoneCode,
              phone: contactDetails?.phone,
              email: contactDetails?.email,
            }}
            user
          />

          <div style={{ marginTop: "40px" }}>
            <CommonHeader
              englishHeader={"Saved Delivery Addresses"}
              arabicHeader={"عناوين التسليم المحفوظة"}
              fontWeight={500}
            />
          </div>

          {addressData.map((address, i) => (
            <div key={i}>
              <div onClick={() => handleClick(i)}>
                {/* <AddressCard
                  addressData={address}
                  vendorData={details.vendor}
                  language={language}
                  deleteAddress={deleteAddress}
                  updateAddress={updateAddress}
                  type={type}
                  isClicked
                /> */}
                <Card
                  cardClick={() => handleAddressClick(address)}
                  icon={getAddressType(address.addressType, "", "28px")}
                  info={{
                    name: address?.addressName,
                    area:
                      language === "ltr"
                        ? address.area
                        : address.ar_area
                        ? address.ar_area
                        : address.area,
                    addressFirst: `${language == "ltr" ? "Street" : "شارع "} ${
                      address?.street
                    }
                    ${", "}
                    ${language == "ltr" ? "Block" : "قطعة "} ${address?.block}
                    ${", "}
                    ${houseLabel(address?.addressType)} ${address?.house}
                    ${
                      address?.avenue || address?.floor || address?.flat
                        ? ", "
                        : ""
                    }`,
                    addressSecond: `${
                      address?.avenue
                        ? language == "ltr"
                          ? "Avenue"
                          : "جادة"
                        : ""
                    }
                     ${address?.avenue ? address?.avenue : ""}
                     ${address?.floor ? ", " : ""}

                    ${
                      address?.floor
                        ? language == "ltr"
                          ? "Floor No."
                          : "رقم الدور"
                        : ""
                    }
                      ${address?.floor ? address?.floor : ""}
                      ${address?.flat ? ", " : ""}
                    ${address?.flat ? flatLabel(address?.addressType) : ""}
                    ${address?.flat ? address?.flat : ""}`,
                    special_directions: address.special_directions,
                  }}
                  symbol={<ThreeDots />}
                  onEdit={(e) => {
                    updateAddress(address?.id);
                  }}
                  onDelete={() => deleteAddress(address?.id)}
                />
              </div>
            </div>
          ))}

          {addressData?.length > 0 ? <Divider /> : null}

          <Card
            cardClick={() => {
              setAreaDetails({
                ...areaDetails,
                area: "",
                branch: "",
                branch_id: "",
                area_id: "",
              });
              setAddressDetails({
                block: "",
                street: "",
                avenue: "",
                house: "",
                floor: "",
                flat: "",
                special_directions: "",
                lat: 29.378,
                lng: 47.99,
                fixedLat: 29.378,
                fixedLng: 47.99,
                addressString: "",
                addressType: "1",
              });
              history.push(`/delivery-address`);
            }}
            icon={<Pointer />}
            info={{
              name:
                language === "ltr"
                  ? "Deliver to new location"
                  : "تسليم إلى الموقع الجديد",
              desc:
                language === "ltr"
                  ? "Add new address to deliver to."
                  : "أضف عنوانًا جديدًا للتوصيل إليه.",
            }}
            symbol={<RightArrow size={32} strokeWidth={1} />}
          />
        </>
      </div>

      {loading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "8",
          }}
          className="order-spinner-background"
        >
          <Spinner
            height="50px"
            color={details?.vendor?.vendor_color}
            size="6px"
          />
        </div>
      )}
    </div>
  );
};

export default AddressSection;
