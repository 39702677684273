//Production Url
export const BOOK_URL = "https://payzah.net/production770/booking-api/index";

export const API_URL = "https://payzah.net/production770/estore-api/estore";

export const DELIVERY_API =
  "https://payzah.net/production770/estore-api/delivery";

export const User_API = "https://payzah.net/production770/estore-api/user";

//Development Url
// export const BOOK_URL = "https://development.payzah.net/booking-api/index";

// export const API_URL = "https://development.payzah.net/estore-api/estore";

// export const DELIVERY_API =
//   "https://development.payzah.net/estore-api/delivery";

// export const User_API = "https://development.payzah.net/estore-api/user";

//Staging Url
// export const BOOK_URL =
//   "https://staging.payzah.net/production770/booking-api/index";

// export const API_URL =
//   "https://staging.payzah.net/production770/estore-api/estore";

// export const DELIVERY_API =
//   "https://staging.payzah.net/production770/estore-api/delivery";

// export const User_API =
//   "https://staging.payzah.net/production770/estore-api/user";

//Local Url
// export const BOOK_URL =
//   "https://a710-182-66-84-210.ngrok-free.app/payzahEstoreWp/booking-api/index";

// export const API_URL =
//   "https://a710-182-66-84-210.ngrok-free.app/payzahEstoreWp/estore-api/estore";

// export const DELIVERY_API =
//   "https://a710-182-66-84-210.ngrok-free.app/payzahEstoreWp/estore-api/delivery";

// export const User_API =
//   "https://a710-182-66-84-210.ngrok-free.app/payzahEstoreWp/estore-api/user";

export const telecount = {
  BD: "Bangladesh 880",
  BE: "Belgium 32",
  BF: "Burkina Faso 226",
  BG: "Bulgaria 359",
  BA: "Bosnia and Herzegovina 387",
  BB: "Barbados 1-246",
  WF: "Wallis and Futuna 681",
  BL: "Saint Barthelemy 590",
  BM: "Bermuda 1-441",
  BN: "Brunei 673",
  BO: "Bolivia 591",
  BH: "Bahrain 973",
  BI: "Burundi 257",
  BJ: "Benin 229",
  BT: "Bhutan 975",
  JM: "Jamaica 1-876",
  BV: "",
  BW: "Botswana 267",
  WS: "Samoa 685",
  BQ: "Curacao 599",
  BR: "Brazil 55",
  BS: "Bahamas 1-242",
  JE: "Jersey 44-1534",
  BY: "Belarus 375",
  BZ: "Belize 501",
  RU: "Russia 7",
  RW: "Rwanda 250",
  RS: "Serbia 381",
  TL: "East Timor 670",
  RE: "Reunion 262",
  TM: "Turkmenistan 993",
  TJ: "Tajikistan 992",
  RO: "Romania 40",
  TK: "Tokelau 690",
  GW: "Guinea-Bissau 245",
  GU: "Guam 1-671",
  GT: "Guatemala 502",
  GS: "",
  GR: "Greece 30",
  GQ: "Equatorial Guinea 240",
  GP: "Saint Barthelemy 590",
  JP: "Japan 81",
  GY: "Guyana 592",
  GG: "Guernsey 44-1481",
  GF: "594",
  GE: "Georgia 995",
  GD: "Grenada 1-473",
  GB: "United Kingdom 44",
  GA: "Gabon 241",
  SV: "El Salvador 503",
  GN: "Guinea 224",
  GM: "Gambia 220",
  GL: "Greenland 299",
  GI: "Gibraltar 350",
  GH: "Ghana 233",
  OM: "Oman 968",
  TN: "Tunisia 216",
  JO: "Jordan 962",
  HR: "Croatia 385",
  HT: "Haiti 509",
  HU: "Hungary 36",
  HK: "Hong Kong 852",
  HN: "Honduras 504",
  HM: " ",
  VE: "Venezuela 58",
  PR: "Puerto Rico 1-787",
  PS: "Palestine 970",
  PW: "Palau 680",
  PT: "Portugal 351",
  SJ: "Svalbard and Jan Mayen 47",
  PY: "Paraguay 595",
  IQ: "Iraq 964",
  PA: "Panama 507",
  PF: "French Polynesia 689",
  PG: "Papua New Guinea 675",
  PE: "Peru 51",
  PK: "Pakistan 92",
  PH: "Philippines 63",
  PN: "Pitcairn 870",
  PL: "Poland 48",
  PM: "Saint Pierre and Miquelon 508",
  ZM: "Zambia 260",
  EH: "Western Sahara 212",
  EE: "Estonia 372",
  EG: "Egypt 20",
  ZA: "South Africa 27",
  EC: "Ecuador 593",
  IT: "Italy 39",
  VN: "Vietnam 84",
  SB: "Solomon Islands 677",
  ET: "Ethiopia 251",
  SO: "Somalia 252",
  ZW: "Zimbabwe 263",
  SA: "Saudi Arabia 966",
  ES: "Spain 34",
  ER: "Eritrea 291",
  ME: "Montenegro 382",
  MD: "Moldova 373",
  MG: "Madagascar 261",
  MF: "Saint Martin 590",
  MA: "Morocco 212",
  MC: "Monaco 377",
  UZ: "Uzbekistan 998",
  MM: "Myanmar 95",
  ML: "Mali 223",
  MO: "Macau 853",
  MN: "Mongolia 976",
  MH: "Marshall Islands 692",
  MK: "Macedonia 389",
  MU: "Mauritius 230",
  MT: "Malta 356",
  MW: "Malawi 265",
  MV: "Maldives 960",
  MQ: "Martinique 596",
  MP: "Northern Mariana Islands 1-670",
  MS: "Montserrat 1-664",
  MR: "Mauritania 222",
  IM: "Isle of Man 44-1624",
  UG: "Uganda 256",
  TZ: "Tanzania 255",
  MY: "Malaysia 60",
  MX: "Mexico 52",
  IL: "Israel 972",
  FR: "France 33",
  IO: "British Indian Ocean Territory 246",
  SH: "Saint Helena 290",
  FI: "Finland 358",
  FJ: "Fiji 679",
  FK: "Falkland Islands 500",
  FM: "Micronesia 691",
  FO: "Faroe Islands 298",
  NI: "Nicaragua 505",
  NL: "Netherlands 31",
  NO: "Norway 47",
  NA: "Namibia 264",
  VU: "Vanuatu 678",
  NC: "New Caledonia 687",
  NE: "Niger 227",
  NF: "Norfolk Island 672",
  NG: "Nigeria 234",
  NZ: "New Zealand 64",
  NP: "Nepal 977",
  NR: "Nauru 674",
  NU: "Niue 683",
  CK: "Cook Islands 682",
  XK: "Republic of Kosovo 383",
  CI: "Ivory Coast 225",
  CH: "Switzerland 41",
  CO: "Colombia 57",
  CN: "China 86",
  CM: "Cameroon 237",
  CL: "Chile 56",
  CC: "Cocos Islands 61",
  CA: "Canada 1",
  CG: "Republic of the Congo 242",
  CF: "Central African Republic 236",
  CD: "Democratic Republic of the Congo 243",
  CZ: "Czech Republic 420",
  CY: "Cyprus 357",
  CX: "Christmas Island 61",
  CR: "Costa Rica 506",
  CW: "Curacao 599",
  CV: "Cape Verde 238",
  CU: "Cuba 53",
  SZ: "Swaziland 268",
  SY: "Syria 963",
  SX: "Sint Maarten 599",
  KG: "Kyrgyzstan 996",
  KE: "Kenya 254",
  SS: "South Sudan 211",
  SR: "Suriname 597",
  KI: "Kiribati 686",
  KH: "Cambodia 855",
  KN: "Saint Kitts and Nevis 1-869",
  KM: "Comoros 269",
  ST: "Sao Tome and Principe 239",
  SK: "Slovakia 421",
  KR: "South Korea 82",
  SI: "Slovenia 386",
  KP: "North Korea 850",
  KW: "Kuwait 965",
  SN: "Senegal 221",
  SM: "San Marino 378",
  SL: "Sierra Leone 232",
  SC: "Seychelles 248",
  KZ: "Kazakhstan 7",
  KY: "Cayman Islands 1-345",
  SG: "Singapore 65",
  SE: "Sweden 46",
  SD: "Sudan 249",
  DO: "Dominican Republic 1-809",
  DM: "Dominica 1-767",
  DJ: "Djibouti 253",
  DK: "Denmark 45",
  VG: "British Virgin Islands 1-284",
  DE: "Germany 49",
  YE: "Yemen 967",
  DZ: "Algeria 213",
  US: "United States 1",
  UY: "Uruguay 598",
  YT: "Mayotte 262",
  UM: "United States Minor Outlying Islands 1",
  LB: "Lebanon 961",
  LC: "Saint Lucia 1-758",
  LA: "Laos 856",
  TV: "Tuvalu 688",
  TW: "Taiwan 886",
  TT: "Trinidad and Tobago 1-868",
  TR: "Turkey 90",
  LK: "Sri Lanka 94",
  LI: "Liechtenstein 423",
  LV: "Latvia 371",
  TO: "Tonga 676",
  LT: "Lithuania 370",
  LU: "Luxembourg 352",
  LR: "Liberia 231",
  LS: "Lesotho 266",
  TH: "Thailand 66",
  TF: "",
  TG: "Togo 228",
  TD: "Chad 235",
  TC: "Turks and Caicos Islands 1-649",
  LY: "Libya 218",
  VA: "Vatican 379",
  VC: "Saint Vincent and the Grenadines 1-784",
  AE: "United Arab Emirates 971",
  AD: "Andorra 376",
  AG: "Antigua and Barbuda 1-268",
  AF: "Afghanistan 93",
  AI: "Anguilla 1-264",
  VI: "U.S. Virgin Islands 1-340",
  IS: "Iceland 354",
  IR: "Iran 98",
  AM: "Armenia 374",
  AL: "Albania 355",
  AO: "Angola 244",
  AQ: "Antarctica 672",
  AS: "American Samoa 1-684",
  AR: "Argentina 54",
  AU: "Australia 61",
  AT: "Austria 43",
  AW: "Aruba 297",
  IN: "India 91",
  AX: "Åland Islands 358-18",
  AZ: "Azerbaijan 994",
  IE: "Ireland 353",
  ID: "Indonesia 62",
  UA: "Ukraine 380",
  QA: "Qatar 974",
  MZ: "Mozambique 258",
};

export const tele = {
  BD: "880",
  BE: "32",
  BF: "226",
  BG: "359",
  BA: "387",
  BB: "1-246",
  WF: "681",
  BL: "590",
  BM: "1-441",
  BN: "673",
  BO: "591",
  BH: "973",
  BI: "257",
  BJ: "229",
  BT: "975",
  JM: "1-876",
  BV: "",
  BW: "267",
  WS: "685",
  BQ: "599",
  BR: "55",
  BS: "1-242",
  JE: "44-1534",
  BY: "375",
  BZ: "501",
  RU: "7",
  RW: "250",
  RS: "381",
  TL: "670",
  RE: "262",
  TM: "993",
  TJ: "992",
  RO: "40",
  TK: "690",
  GW: "245",
  GU: "1-671",
  GT: "502",
  GS: "",
  GR: "30",
  GQ: "240",
  GP: "590",
  JP: "81",
  GY: "592",
  GG: "44-1481",
  GF: "594",
  GE: "995",
  GD: "1-473",
  GB: "44",
  GA: "241",
  SV: "503",
  GN: "224",
  GM: "220",
  GL: "299",
  GI: "350",
  GH: "233",
  OM: "968",
  TN: "216",
  JO: "962",
  HR: "385",
  HT: "509",
  HU: "36",
  HK: "852",
  HN: "504",
  HM: " ",
  VE: "58",
  PR: "1-787",
  PS: "970",
  PW: "680",
  PT: "351",
  SJ: "47",
  PY: "595",
  IQ: "964",
  PA: "507",
  PF: "689",
  PG: "675",
  PE: "51",
  PK: "92",
  PH: "63",
  PN: "870",
  PL: "48",
  PM: "508",
  ZM: "260",
  EH: "212",
  EE: "372",
  EG: "20",
  ZA: "27",
  EC: "593",
  IT: "39",
  VN: "84",
  SB: "677",
  ET: "251",
  SO: "252",
  ZW: "263",
  SA: "966",
  ES: "34",
  ER: "291",
  ME: "382",
  MD: "373",
  MG: "261",
  MF: "590",
  MA: "212",
  MC: "377",
  UZ: "998",
  MM: "95",
  ML: "223",
  MO: "853",
  MN: "976",
  MH: "692",
  MK: "389",
  MU: "230",
  MT: "356",
  MW: "265",
  MV: "960",
  MQ: "596",
  MP: "1-670",
  MS: "1-664",
  MR: "222",
  IM: "44-1624",
  UG: "256",
  TZ: "255",
  MY: "60",
  MX: "52",
  IL: "972",
  FR: "33",
  IO: "246",
  SH: "290",
  FI: "358",
  FJ: "679",
  FK: "500",
  FM: "691",
  FO: "298",
  NI: "505",
  NL: "31",
  NO: "47",
  NA: "264",
  VU: "678",
  NC: "687",
  NE: "227",
  NF: "672",
  NG: "234",
  NZ: "64",
  NP: "977",
  NR: "674",
  NU: "683",
  CK: "682",
  XK: "",
  CI: "225",
  CH: "41",
  CO: "57",
  CN: "86",
  CM: "237",
  CL: "56",
  CC: "61",
  CA: "1",
  CG: "242",
  CF: "236",
  CD: "243",
  CZ: "420",
  CY: "357",
  CX: "61",
  CR: "506",
  CW: "599",
  CV: "238",
  CU: "53",
  SZ: "268",
  SY: "963",
  SX: "599",
  KG: "996",
  KE: "254",
  SS: "211",
  SR: "597",
  KI: "686",
  KH: "855",
  KN: "1-869",
  KM: "269",
  ST: "239",
  SK: "421",
  KR: "82",
  SI: "386",
  KP: "850",
  KW: "965",
  SN: "221",
  SM: "378",
  SL: "232",
  SC: "248",
  KZ: "7",
  KY: "1-345",
  SG: "65",
  SE: "46",
  SD: "249",
  DO: "1-809",
  DM: "1-767",
  DJ: "253",
  DK: "45",
  VG: "1-284",
  DE: "49",
  YE: "967",
  DZ: "213",
  US: "1",
  UY: "598",
  YT: "262",
  UM: "1",
  LB: "961",
  LC: "1-758",
  LA: "856",
  TV: "688",
  TW: "886",
  TT: "1-868",
  TR: "90",
  LK: "94",
  LI: "423",
  LV: "371",
  TO: "676",
  LT: "370",
  LU: "352",
  LR: "231",
  LS: "266",
  TH: "66",
  TF: "",
  TG: "228",
  TD: "235",
  TC: "1-649",
  LY: "218",
  VA: "379",
  VC: "1-784",
  AE: "971",
  AD: "376",
  AG: "1-268",
  AF: "93",
  AI: "1-264",
  VI: "1-340",
  IS: "354",
  IR: "98",
  AM: "374",
  AL: "355",
  AO: "244",
  AQ: "",
  AS: "1-684",
  AR: "54",
  AU: "61",
  AT: "43",
  AW: "297",
  IN: "91",
  AX: "358-18",
  AZ: "994",
  IE: "353",
  ID: "62",
  UA: "380",
  QA: "974",
  MZ: "258",
};
