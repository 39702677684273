import axios from "axios";
import { API_URL, BOOK_URL } from "../../services/constants";

export const getUserLocation = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVendorSlug = async ({ host }) => {
  try {
    const response = await axios.post(
      `${API_URL}/get-vendor-slug`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        host: host,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCategoryAndProducts = async ({
  vendorSlug,
  vendors_id,
  area_id,
}) => {
  try {
    const response = await axios.post(
      `${API_URL}/homepage`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_slug: vendorSlug,
        vendor_id: vendors_id,
        area_id: area_id,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBookingHomepage = async ({ vendorSlug, booking_vendor_id }) => {
  try {
    const response = await axios.post(
      `${BOOK_URL}`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_slug: vendorSlug,
        booking_vendor_id: booking_vendor_id,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBookingBranches = async ({ vendorSlug, booking_vendor_id }) => {
  try {
    const response = await axios.post(
      `${BOOK_URL}/branches`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_slug: vendorSlug,
        booking_vendor_id: booking_vendor_id,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const emptyUserCart = async ({ vendorSlug, vendor_id, user_string }) => {
  try {
    const response = await axios.post(
      `${API_URL}/empty-cart`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_id: vendor_id,
        vendor_slug: vendorSlug,
        user_string: user_string,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserCart = async ({
  vendorSlug,
  vendor_id,
  user_string,
  area_id,
}) => {
  try {
    const response = await axios.post(
      `${API_URL}/get-user-cart`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_id: vendor_id,
        vendor_slug: vendorSlug,
        area_id: area_id,
        user_string: user_string,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
