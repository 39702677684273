import React, { useContext, useEffect, useState } from "react";
import {
  ContactContext,
  LanguageContext,
  UserContext,
  VendorContext,
} from "../../../App";
import { Link, useHistory } from "react-router-dom";
import NewAddressFormField from "./NewAddressFormField";
import HomeIcon from "../../../SVGs/addressIcons/HomeIcon";
import ApparentIcon from "../../../SVGs/addressIcons/ApparentIcon";
import OfficeIcons from "../../../SVGs/addressIcons/OfficeIcons";
import MosqueIcon from "../../../SVGs/addressIcons/MosqueIcon";
import SchoolIcon from "../../../SVGs/addressIcons/SchoolIcon";
import GovernmentIcon from "../../../SVGs/addressIcons/GovernmentIcon";
import CommonHeader from "../../NewCheckOutPage/Components/CommonHeader";
import AreaModal from "../../AreaModal/AreaModal";
import Divider from "../../Divider/Divider";

const NewAddressForm = ({
  areaDetails,
  errorState,
  houseValidation,
  streetValidation,
  blockValidation,
  addressNameValidation,
  handleMapChanges,
  setMarkerPosition,
}) => {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { addressDetails, setAddressDetails } = useContext(ContactContext);
  const history = useHistory();
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [showAreaModal, setShowAreaModal] = useState(false);

  const getPlaceHolder = () => {
    switch (addressDetails.addressType) {
      case "1":
      case "2":
        return language === "ltr"
          ? "My House, Family House"
          : "بيتي، بيت العائلة";
      case "3":
        return language === "ltr" ? "My Office" : "مكتبي";
      case "4":
        return language === "ltr" ? "Sons School..." : "مدرسة أبناء...";

      default:
        return language === "ltr"
          ? "My House, Family House"
          : "بيتي، بيت العائلة";
    }
  };

  return (
    <div /* style={{ paddingBottom: "50px" }} */>
      <CommonHeader
        englishHeader={"Add your delivery details"}
        arabicHeader={"أضف تفاصيل التسليم الخاصة بك"}
        fontWeight={500}
      />
      <div className="delivery-address-address-select">
        <div className="delivery-address-type-select nav nav-tabs">
          <div
            className={`delivery-address-type ${
              addressDetails.addressType === "1" ? "deliver-type-active" : ""
            } ${
              details?.vendor?.home_page_type === "18" && "fashion-theme-border"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setAddressDetails((prev) => ({ ...prev, addressType: "1" }));
            }}
          >
            <div className="Address-icon-names">
              <div className="navlink-icon">
                <HomeIcon height={34} />
              </div>
              <div
                className={
                  addressDetails.addressType === "1"
                    ? "navlink-text-active delivery-type-text"
                    : "navlink-text delivery-type-text"
                }
              >
                {language == "ltr" ? "House" : "منزل"}
              </div>
            </div>
          </div>

          <div
            className={`delivery-address-type ${
              addressDetails.addressType === "2" ? "deliver-type-active" : ""
            } ${
              details?.vendor?.home_page_type === "18" && "fashion-theme-border"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setAddressDetails((prev) => ({ ...prev, addressType: "2" }));
            }}
          >
            <div className="Address-icon-names">
              <div className="navlink-icon">
                <ApparentIcon height={32} />
              </div>
              <div
                className={
                  addressDetails.addressType === "2"
                    ? "navlink-text-active delivery-type-text"
                    : "navlink-text delivery-type-text"
                }
              >
                {language == "ltr" ? "Apartment" : "شقة"}
              </div>
            </div>
          </div>

          <div
            className={`delivery-address-type ${
              addressDetails.addressType === "3" ? "deliver-type-active" : ""
            } ${
              details?.vendor?.home_page_type === "18" && "fashion-theme-border"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setAddressDetails((prev) => ({ ...prev, addressType: "3" }));
            }}
          >
            <div className="Address-icon-names">
              <div className="navlink-icon">
                <OfficeIcons height={32} />
                {/* <i className='fa fa-briefcase' style={{ fontSize: "30px", padding: "8px", color: addressDetails.addressType === "3" ? details?.vendor?.vendor_color : "#949494" }}></i> */}
              </div>
              <div
                className={
                  addressDetails.addressType === "3"
                    ? "navlink-text-active delivery-type-text"
                    : "navlink-text delivery-type-text"
                }
              >
                {language == "ltr" ? "Office" : "مكتب"}
              </div>
            </div>
          </div>
          {details?.vendor?.enable_address_types?.includes("4") ? (
            <div
              className={`delivery-address-type ${
                addressDetails.addressType === "4" ? "deliver-type-active" : ""
              } ${
                details?.vendor?.home_page_type === "18" &&
                "fashion-theme-border"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setAddressDetails((prev) => ({ ...prev, addressType: "4" }));
              }}
            >
              <div className="Address-icon-names">
                <div className="navlink-icon">
                  <SchoolIcon height={32} />
                </div>
                <div
                  className={
                    addressDetails.addressType === "4"
                      ? "navlink-text-active delivery-type-text"
                      : "navlink-text delivery-type-text"
                  }
                >
                  {language == "ltr" ? "School" : "مدرسة"}
                </div>
              </div>
            </div>
          ) : null}
          {details?.vendor?.enable_address_types?.includes("5") ? (
            <div
              className={`delivery-address-type ${
                addressDetails.addressType === "5" ? "deliver-type-active" : ""
              } ${
                details?.vendor?.home_page_type === "18" &&
                "fashion-theme-border"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setAddressDetails((prev) => ({ ...prev, addressType: "5" }));
              }}
            >
              <div className="Address-icon-names">
                <div className="navlink-icon">
                  <MosqueIcon height={32} />
                </div>
                <div
                  className={
                    addressDetails.addressType === "5"
                      ? "navlink-text-active delivery-type-text"
                      : "navlink-text delivery-type-text"
                  }
                >
                  {language == "ltr" ? "Mosque" : "مسجد"}
                </div>
              </div>
            </div>
          ) : null}
          {details?.vendor?.enable_address_types?.includes("6") ? (
            <div
              className={`delivery-address-type ${
                addressDetails.addressType === "6" ? "deliver-type-active" : ""
              } ${
                details?.vendor?.home_page_type === "18" &&
                "fashion-theme-border"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setAddressDetails((prev) => ({ ...prev, addressType: "6" }));
              }}
            >
              <div className="Address-icon-names">
                <div className="navlink-icon">
                  <GovernmentIcon height={32} />
                </div>
                <div
                  className={
                    addressDetails.addressType === "6"
                      ? "navlink-text-active delivery-type-text"
                      : "navlink-text delivery-type-text"
                  }
                >
                  {language == "ltr" ? "Government" : "مبنى حكومة"}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {userDetails?.is_guest ? null : (
        <div
          className="delivery-address-form-div1"
          style={{ marginBottom: "20px" }}
        >
          <div style={{ position: "relative", width: "100%" }}>
            <label
              className="label-name"
              style={{ position: "relative", top: 0 }}
            >
              {language == "ltr"
                ? "Name this location"
                : "قم بتسمية هذا الموقع"}{" "}
              <sup>*</sup>
            </label>
            <input
              type="text"
              className="form-control custom-placeholder"
              placeholder={getPlaceHolder()}
              id="addressName"
              name="addressName"
              required="true"
              value={addressDetails.addressName}
              onChange={(e) => {
                addressNameValidation(e.target.value.trim());
                setAddressDetails((k) => ({
                  ...k,
                  addressName: e.target.value,
                }));
              }}
            ></input>
          </div>
          {errorState.addressNameError && (
            <label className="error-text">
              {language == "ltr"
                ? errorState.addressNameErrorMessage
                : errorState.addressNameErrorMessagear}
            </label>
          )}
        </div>
      )}

      <div
        /* to="/area" */ className="delivery-address-form-div1"
        onClick={() => setShowAreaModal(!showAreaModal)}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <label
            className="label-name"
            style={{ position: "relative", top: 0 }}
          >
            {language === "ltr" ? "Area" : "منطقة"}
          </label>
          <div className="delivery-selected-address">
            <span
              className="delivery-selected-address-area"
              style={
                areaDetails?.area === ""
                  ? { color: "#B3B3B3", fontWeight: "500" }
                  : {}
              }
            >
              {areaDetails?.area === ""
                ? language == "ltr"
                  ? "Select delivery location"
                  : "حدد موقع التسليم"
                : language == "ltr"
                ? areaDetails?.area
                : areaDetails?.ar_area}
            </span>
            <i className="fa fa-angle-right right-arrow"></i>
          </div>
        </div>
        {showAreaModal ? (
          <AreaModal
            setMarkerPosition={setMarkerPosition}
            showAreaModal={showAreaModal}
            handleClose={() => {
              setShowAreaModal(false);
            }}
          />
        ) : null}
        {errorState.areaNameError && (
          <label className="error-text">
            {language == "ltr"
              ? errorState.areaNameErrorMessage
              : errorState.areaNameErrorMessagear}
          </label>
        )}
      </div>
      <div
        className="form-tab-section checkout-one"
        style={{ paddingTop: "25px" }}
      >
        <NewAddressFormField
          blockValidation={blockValidation}
          streetValidation={streetValidation}
          houseValidation={houseValidation}
          errorState={errorState}
          addressType={addressDetails.addressType}
          // handleMapChanges={handleMapChanges}
        />
      </div>
      {!userDetails?.is_guest ? (
        <div
          className="blueBox"
          style={{ marginTop: "25px", marginBottom: "40px" }}
        >
          <div>
            <div class="round">
              <input
                type="checkbox"
                value={addressDetails.is_primary}
                checked={
                  addressDetails.is_primary ||
                  userDetails?.address?.length === 0
                }
                onClick={(e) => {
                  if (userDetails?.address?.length !== 0) {
                    setAddressDetails((k) => ({
                      ...k,
                      is_primary: e.target.checked,
                    }));
                  }
                }}
                id="is_primary"
                name="is_primary"
              />
              <label for="is_primary"></label>
            </div>
            {/* <input
            type="checkbox"
            id="primaryAddress"
            name="primaryAddress"
            value={addressDetails.primaryAddress}
            checked={
              addressDetails.primaryAddress ||
              userDetails?.address?.length === 0
            }
            onClick={(e) => {
              if (userDetails?.address?.length !== 0) {
                setAddressDetails((k) => ({
                  ...k,
                  primaryAddress: e.target.checked,
                }));
              }
            }}
            className="rounded-checkbox"
          /> */}
          </div>

          <div>
            <div
              style={{
                fontSize: language === "ltr" ? "13px" : "14px",
                fontWeight: 500,
              }}
            >
              {language === "ltr"
                ? "Mark it as default delivery address"
                : "ضع علامة عليه كعنوان التسليم الافتراضي"}
            </div>
            <div
              style={{
                color: "#636363",
                fontSize: language === "ltr" ? "11px" : "12px",
              }}
            >
              {language === "ltr"
                ? "Make this address the default address to deliver your orders to it."
                : "اجعل هذا العنوان هو العنوان الافتراضي لتوصيل طلباتك إليه."}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ margin: "50px 0" }}></div>
      )}
    </div>
  );
};

export default NewAddressForm;
