import React, { useContext, useEffect } from "react";
import {
  AreaContext,
  InterNationalContext,
  LanguageContext,
  VendorContext,
} from "../../App";
import ModeSelector from "./Component/ModeSelector";
import "./neworderMode.css";
import DeliveryAreas from "./Component/DeliveryAreas";
import PickupArea from "./Component/PickupArea";
import CommonBack from "../NewCheckOutPage/Components/CommonBack";
import HomepageDesign from "../InterNationalDelivery/HomepageDesign";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NewOrderMode = () => {
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);
  const { areaDetails, setOpenArea } = useContext(AreaContext);
  const { internationalDelivery } = useContext(InterNationalContext);
  const history = useHistory();

  // useEffect(() => {
  //   console.log(internationalDelivery, "internationalDelivery");
  //   console.log(
  //     internationalDelivery &&
  //       internationalDelivery?.delivery_country_code !== "KW",
  //     "internationalDelivery"
  //   );
  //   if (
  //     internationalDelivery &&
  //     internationalDelivery?.delivery_country_code !== "KW"
  //   ) {
  //     console.log("new order mode 1");
  //     history.push("/");
  //   }
  // }, [internationalDelivery?.delivery_country_code]);

  const handleCountryChange = (code) => {
    if (internationalDelivery && code !== "KW") {
      history.push("/");
    }
  };

  return (
    <div
      style={{
        overflowY: "auto",
        paddingRight: 10,
        height: "90vh",
        overflowX: "hidden",
        paddingLeft: 10,
        color: "#000",
        borderRadius: "20px",
        width: "100%",
        position: "relative",
      }}
    >
      <CommonBack
        variant="light"
        clickButton={() =>
          setOpenArea((prev) => ({ open: true, goHome: false }))
        }
      />
      {details?.vendor?.international_delivery !== "3" &&
      details?.vendor?.international_delivery !== "" ? (
        <div style={{ padding: "0 19px" }}>
          <HomepageDesign handleCountryChange={handleCountryChange} />
        </div>
      ) : null}
      {internationalDelivery &&
      internationalDelivery.delivery_country_code === "KW" ? (
        <div className={`newOrderMode-mainDiv `}>
          <ModeSelector details={details} language={language} />
          {areaDetails?.type === "delivery" ? (
            <DeliveryAreas />
          ) : areaDetails?.type === "pickup" ? (
            <PickupArea />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default NewOrderMode;
