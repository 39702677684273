import React, { useContext } from 'react'
import { LanguageContext } from '../../../App'

const CommonHeader = ({ englishHeader, arabicHeader, fontWeight }) => {
    const { language } = useContext(LanguageContext)
    return (
        <div className='commonHeader-Div' style={{ fontWeight: fontWeight }}>
            {
                language === "ltr" ?
                    englishHeader :
                    arabicHeader
            }
        </div>
    )
}

export default CommonHeader