import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AreaContext, VendorContext } from "../../App";
import DeliveryTimeHeader from "./components/DeliveryTimeHeader";
import DeliveryTimeSelect from "./components/DeliveryTimeSelect";
import SnapPixel from "react-snapchat-pixel";

function DeliveryTimePage() {
  const details = useContext(VendorContext);
  const { areaDetails } = useContext(AreaContext);
  const history = useHistory();

  useEffect(() => {
    if (areaDetails?.customDelivery && areaDetails?.type != "pickup") {
      history.push("/");
    }
  }, [details?.vendor, areaDetails]);

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();
  }, []);

  return (
    <>
      <DeliveryTimeHeader></DeliveryTimeHeader>
      <DeliveryTimeSelect></DeliveryTimeSelect>
    </>
  );
}

export default DeliveryTimePage;
