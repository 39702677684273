import React, { useContext, useEffect, useState } from "react";
import {
  ContactContext,
  InterNationalContext,
  LanguageContext,
  UserContext,
  VendorContext,
} from "../../../App";
import ReactFlagsSelect from "react-flags-select";
import CommonSectionHeader from "../../NewCheckOutPage/Components/CommonSectionHeader";
import { tele, telecount } from "../../../services/constants";
import CommonHeader from "../../NewCheckOutPage/Components/CommonHeader";
import Spinner from "../../../SVGs/Spinner";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { GetUserDetails, updateUserDetails } from "../../APIS/userApi";
import BackComponent from "../../NewCheckOutPage/Components/BackComponent";
import "./userInfo.css";

const UserInfo = () => {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { contactDetails, setContactDetails } = useContext(ContactContext);
  const { internationalDelivery } = useContext(InterNationalContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [errorContactDetails, setErrorContactDetails] = useState({
    emailError: false,
    emailErrorMessage: "",
    emailErrorMessagear: "",
    nameError: false,
    nameErrorMessage: "",
    nameErrorMessagear: "",
    phoneError: false,
    phoneErrorMessage: "",
    phoneErrorMessagear: "",
  });
  const { userDetails, setUserDetails, resetUserDetails } =
    useContext(UserContext);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
  }, []);

  const nameValidation = (value) => {
    let pattern =
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ ]*$/;
    if (value == "") {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "This field is compulsory",
        nameErrorMessagear: "مطلوب ملء هذا الحقل",
        nameError: true,
      }));
      return true;
    } else if (value.match(pattern)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "",
        nameError: false,
        nameErrorMessagear: "",
      }));
      return false;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "Only alphabets are allowed",
        nameError: true,
        nameErrorMessagear: "يسمح فقط الحروف الهجائية",
      }));
      return true;
    }
  };

  const phoneValidation = (value) => {
    let pattern = /^[0-9]+$/;
    let kwpattern = /^[124965]\d+$/;
    if (value === "") {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "This field is compulsory",
        phoneError: true,
        phoneErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else if (value.length < 8) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Please enter at least 8 characters",
        phoneError: true,
        phoneErrorMessagear: "الرجاء إدخال 8 أرقام",
      }));
      return true;
    } else if (!value.match(pattern)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Only numbers allowed",
        phoneError: true,
        phoneErrorMessagear: "مسموح بالأرقام فقط",
      }));
      return true;
    } else if (contactDetails?.phoneCode == "KW" && !value.match(kwpattern)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Enter a valid phone number",
        phoneError: true,
        phoneErrorMessagear: "أدخل رقم هاتف صالح",
      }));
      return true;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "",
        phoneError: false,
        phoneErrorMessagear: "",
      }));
      return false;
    }
  };

  const emailValidation = (value) => {
    let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value === "") {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        emailErrorMessage: "This field is compulsory",
        emailError: true,
        emailErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else if (!pattern.test(value)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        emailErrorMessage: "Enter a valid email",
        emailError: true,
        emailErrorMessagear: "البريد الالكتروني غير صحيح",
      }));
      return true;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        emailErrorMessage: "",
        emailError: false,
        emailErrorMessagear: "",
      }));
      return false;
    }
  };

  useEffect(() => {
    if (internationalDelivery && internationalDelivery.country_code) {
      setContactDetails({
        ...contactDetails,
        phoneCode: internationalDelivery.country_code,
      });
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("contactInfo"))) {
      const contactInfo = JSON.parse(localStorage.getItem("contactInfo"));

      setContactDetails({
        ...contactDetails,
        phoneCode: contactInfo.code,
      });
    }
  }, []);

  const modifyValue = (phoneNumber) => {
    if (phoneNumber.includes("+")) {
      const data = phoneNumber.split(" ");
      let finalNumber = "";
      if (data.length === 1) {
        const newData = data[0].split("").slice(4, 12);
        finalNumber = newData.join("");
      } else {
        data.map((num, i) => {
          if (i !== 0) {
            finalNumber += num;
          }
        });
      }
      return finalNumber;
    } else {
      return phoneNumber;
    }
  };

  const updateUserInfo = async () => {
    let name = nameValidation(contactDetails.name);
    let email = emailValidation(contactDetails.email, true);
    let phone = phoneValidation(contactDetails.phone, true);

    if (!name && !email && !phone) {
      setLoading(true);

      const response = await updateUserDetails({
        vendor_id: details?.vendor.vendors_id,
        country_code: `+${tele[contactDetails.phoneCode]}`,
        phone_number: contactDetails.phone,
        full_name: contactDetails.name,
        email: contactDetails.email,
        jwt_token: localStorage.getItem("token"),
        user_id: localStorage.getItem("id"),
        language: language,
      });

      setLoading(false);

      if (response?.status) {
        const userReponse = await GetUserDetails({
          vendor_id: details?.vendor.vendors_id,
          sendSMS: false,
          country_code: `+${tele[contactDetails.phoneCode]}`,
          phone_number: contactDetails.phone,
          jwt_token: localStorage.getItem("token"),
          user_id: localStorage.getItem("id"),
          language: language,
        });
        if (userReponse?.status) {
          let data = {
            name: userReponse?.data?.name,
            phone: userReponse?.data?.phone,
            email: userReponse?.data?.email,
            code:
              Object.keys(tele).find(
                (ele) =>
                  tele[ele] == userReponse?.data?.country_code.replace("+", "")
              ) ?? "KW",
            expire: new Date().getTime(),
          };
          if (data) {
            localStorage.setItem("contactInfo", JSON.stringify(data));
          }
          setUserDetails({ ...userReponse?.data });
          setContactDetails({
            ...contactDetails,
            name: userReponse?.data?.name,
            email: userReponse?.data?.email,
            phone: userReponse?.data.phone,
            phoneCode:
              Object.keys(tele).find(
                (ele) =>
                  tele[ele] == userReponse?.data?.country_code.replace("+", "")
              ) ?? "KW",
          });
          history.push("/info");
        } else {
          toast.error(userReponse?.message);
          localStorage.removeItem("token");
          localStorage.removeItem("contactInfo");
          resetUserDetails();
          history.push("/");
        }
      } else {
        toast.error(response?.message);
      }
    } else {
      console.log("validation failed");
    }
  };

  return (
    <div className="holder-container">
      <div className="toasterDiv">
        <Toaster position="top-left" reverseOrder={false} />
      </div>

      <div
        onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}
      >
        <BackComponent />
      </div>

      <div>
        <CommonHeader
          englishHeader={"My Information"}
          arabicHeader={"معلوماتي"}
          fontWeight={600}
        />
      </div>
      <CommonSectionHeader
        englishHeader="Contact Details"
        arabicHeader="ارقام التواصل"
      />
      <div style={{ marginTop: "30px" }}>
        <form action="javascript/void" autoComplete="on">
          <div style={{ position: "relative" }}>
            <div className="customerInputsFlex">
              <div
                className="form__group formSemi"
                style={{ position: "relative" }}
              >
                <div className="inputFlag">
                  <div
                    style={
                      details?.vendor?.checkout_method === "2"
                        ? {
                            backgroundColor: "#EAEAEA",
                            borderRadius: "10px",
                            height: "44px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                        : {}
                    }
                  >
                    <ReactFlagsSelect
                      selected={contactDetails?.phoneCode}
                      disabled={details?.vendor?.checkout_method === "2"}
                      searchable={true}
                      showSelectedLabel={false}
                      customLabels={telecount}
                      className="user-info-flag"
                      onSelect={(code) => {
                        setContactDetails({
                          ...contactDetails,
                          phoneCode: code,
                          phone: contactDetails?.phone?.substring(
                            0,
                            code == "KW" ? 8 : 12
                          ),
                        });
                      }}
                    ></ReactFlagsSelect>
                  </div>
                </div>
                <input
                  type="tel"
                  className="form__field hideBorder"
                  placeholder=""
                  name="phone"
                  id="phone"
                  required="true"
                  disabled={details?.vendor?.checkout_method === "2"}
                  value={contactDetails?.phone}
                  autoComplete="tel"
                  onChange={(e) => {
                    const newValue = modifyValue(e.target.value);
                    if (
                      (contactDetails.phoneCode === "KW" &&
                        newValue.length <= 8) ||
                      (contactDetails.phoneCode !== "KW" &&
                        newValue.length <= 10)
                    ) {
                      setContactDetails({
                        ...contactDetails,
                        phone: newValue,
                      });
                    }
                  }}
                />
                {details?.vendor?.checkout_method === "2" ? (
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "25px",
                      display: "flex",
                      alignItems: "center",
                      color: "#fff",
                      padding: "5px 7px",
                      backgroundColor: "#4CAF50",
                      borderRadius: "50px",
                      fontSize: "12px",
                      gap: "5px",
                    }}
                  >
                    <span>✔</span>
                  </div>
                ) : null}
                <label
                  htmlFor="phone"
                  className="form__label phoneLabel dataFilled"
                >
                  {language == "ltr" ? "Phone Number" : "رقم الهاتف"}
                </label>
              </div>

              {errorContactDetails.phoneError && (
                <label className="error-text">
                  {language == "ltr"
                    ? errorContactDetails.phoneErrorMessage
                    : errorContactDetails.phoneErrorMessagear}
                </label>
              )}
            </div>

            <>
              <div className="customerInputsFlex">
                <div className="form__group formSemi">
                  <input
                    type="text"
                    className="form__field hideBorder"
                    placeholder=""
                    id="name"
                    name="name"
                    required="true"
                    autoComplete
                    value={contactDetails.name}
                    onChange={(e) => {
                      // nameValidation(e.target.value);
                      setContactDetails({
                        ...contactDetails,
                        name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="name" className="form__label">
                    {language == "ltr" ? "Full Name" : "الاسم الكامل"}
                  </label>
                </div>
              </div>
              {errorContactDetails.nameError && (
                <label className="error-text">
                  {language == "ltr"
                    ? errorContactDetails.nameErrorMessage
                    : errorContactDetails.nameErrorMessagear}
                </label>
              )}

              <div className="customerInputsFlex">
                <div className="form__group formSemi">
                  <input
                    type="email"
                    className="form__field hideBorder"
                    placeholder=""
                    disabled={details?.vendor?.checkout_method === "1"}
                    id="email"
                    name="email"
                    required="true"
                    autoComplete
                    value={contactDetails.email}
                    onChange={(e) => {
                      setContactDetails({
                        ...contactDetails,
                        email: e.target.value,
                      });
                    }}
                  />
                  {details?.vendor?.checkout_method === "1" ? (
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "25px",
                        display: "flex",
                        alignItems: "center",
                        color: "#fff",
                        padding: "5px 7px",
                        backgroundColor: "#4CAF50",
                        borderRadius: "50px",
                        fontSize: "12px",
                        gap: "5px",
                      }}
                    >
                      <span>✔</span>
                    </div>
                  ) : null}
                  <label htmlFor="email" className="form__label">
                    {language == "ltr" ? "Email" : "البريد الإلكتروني"}
                  </label>
                </div>
              </div>
              {errorContactDetails.emailError && (
                <label className="error-text">
                  {language == "ltr"
                    ? errorContactDetails.emailErrorMessage
                    : errorContactDetails.emailErrorMessagear}
                </label>
              )}
            </>
          </div>
        </form>
      </div>
      <div
        className={`contact-details-bottom-button contact-details-mobile-button ${
          details?.vendor?.home_page_type === "18" && "fashion-theme"
        }`}
      >
        {/* <Link
          className="contact-details-back-button"
          onClick={(e) => {
            e.preventDefault();
            history.push("/");
          }}
        >
          {language === "ltr" ? "Back" : "تراجع"}
        </Link> */}
        <Link className="contact-details-next-button" onClick={updateUserInfo}>
          {language === "ltr" ? "Update" : "تحديث"}
        </Link>
      </div>

      {loading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "8",
          }}
          className="order-spinner-background"
        >
          <Spinner
            height="50px"
            color={details?.vendor?.vendor_color}
            size="6px"
          />
        </div>
      )}
    </div>
  );
};

export default UserInfo;
