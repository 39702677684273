import BoxSvg from "../SVGs/BoxSvg"
import ApparentIcon from "../SVGs/addressIcons/ApparentIcon"
import GovernmentIcon from "../SVGs/addressIcons/GovernmentIcon"
import HomeIcon from "../SVGs/addressIcons/HomeIcon"
import MosqueIcon from "../SVGs/addressIcons/MosqueIcon"
import OfficeIcons from "../SVGs/addressIcons/OfficeIcons"
import SchoolIcon from "../SVGs/addressIcons/SchoolIcon"

export const getAddressType = (addressType, color, height, isPickup) => {
    if (isPickup) {
        return <BoxSvg />
    }
    switch (addressType) {
        case '1':
            return <HomeIcon color={color} height={height} />

        case '2':
            return <ApparentIcon color={color} height={height} />

        case '3':
            return <OfficeIcons color={color} height={height} />
        case '4':
            return <SchoolIcon color={color} height={height} />
        case '5':
            return <MosqueIcon color={color} height={height} />
        case '6':
            return <GovernmentIcon color={color} height={height} />

        default:
            break;
    }

}


export const displayInternationalTime = (deliveryTime, deliveryType) => {
    switch (deliveryType) {
        case '3':
            return deliveryTime === "1" ? { eng: "day", arb: "يوم" } : { eng: "days", arb: "أيام" };
        case '4':
            return deliveryTime === "1" ? { eng: "week", arb: "أسبوع" } : { eng: "weeks", arb: "أسابيع" };
        case '5':
            return deliveryTime === "1" ? { eng: "month", arb: "شهر" } : { eng: "month", arb: "شهور" };
        default:
            break;
    }
}